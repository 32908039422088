import { useAuth } from 'react-oidc-context'
import { NavLink, useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/osgIcons/oslo-logo.svg'
import { ReactComponent as LogOut } from '../../assets/osgIcons/log-out-icon.svg'
import './Header2.scss'

export const Header2 = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  const handleLoggut = () => {
    auth.signoutRedirect()
  }

  return (
    <header
      className={`
          sticky top-0
          bg-white
          border-b-2 border-gray-dark
          flex items-center justify-between
          w-full mobile:pr-2 mobile:pl-2 pr-5 pl-5
          h-nav-large z-10
        `}
    >
      <button>
        <Logo
          className="h-logo-large"
          onClick={() => navigate('/opprett-tommerunde', { replace: true })}
        />
      </button>

      <div className="flex items-center justify-between w-auto text-center">
        {auth.isAuthenticated && (
          <>
            <ul className="grid grid-cols-4 gap-1 text-sm header-elements">
              <li>
                <NavLink to="/opprett-tommerunde">Opprett tømmerunde</NavLink>
              </li>
              <li>
                <NavLink to="/tommerunder">Tømmerunder</NavLink>
              </li>
              <li>
                <NavLink to="/automatoversikt">Automatoversikt</NavLink>
              </li>
              <li>
                <NavLink to="/automattomminger">Automattømminger</NavLink>
              </li>
            </ul>
            <button className="pkt-btn pkt-btn--tertiary pkt-btn--icon-right ml-2" onClick={handleLoggut}>
              <span className="pkt-btn__text text-sm pr-2">Logg ut</span>
              <LogOut className="pkt-icon pkt-btn__icon" />
            </button>
          </>
        )}
      </div>
    </header>
  )
}
