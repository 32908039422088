import { useEffect, useState } from 'react'
import { ReactComponent as ArrowDown } from '../../../assets/osgIcons/arrow-down-thin.svg'
import { ReactComponent as ArrowUp } from '../../../assets/osgIcons/arrow-up-thin.svg'
import { formatDate } from '../../../utils/dateTimeUtils'
import { Loading } from '../../loading/Loading'
import './Table.scss'

export interface TableBodyProps {
  headers: TableHeader
  footers?: TableFooter
  rows: TableRow[]
  isFetchingData: boolean
  fixedTable?: boolean
  initialColumnToSort?: string
}

export interface TableHeader {
  [key: string]: {
    text: string
    sortable: boolean
    class?: string
    initSort?: boolean
  }
}

export interface TableFooter {
  [key: string]: {
    text: string
    class?: string
  }
}

export interface TableRow {
  tableCell: TableCell
  isDisabled?: boolean
  htmlElement?: any
  htmlElementClickFunction: (action: any) => void
  data: any
  cssClass?: string
}

export interface TableCell {
  [headerKey: string]: {
    text: string | number | Date
    cssClass?: string
    iconRightOfText?: any
    iconLeftOfText?: any
    htmlElementClickFunction?: (action: any, key: string) => void
  }
}

export const Table = ({
  rows,
  headers,
  footers,
  isFetchingData,
  fixedTable,
}: TableBodyProps) => {
  const initSortableHeaderText = Object.values(headers).find(
    (k) => k.initSort
  )?.text
  const initSortKey = Object.keys(headers).find(
    (k) => headers[k].text === initSortableHeaderText
  )
  const initSortKeyIndex = Object.keys(headers).findIndex(
    (k) => headers[k].text === initSortableHeaderText
  )

  const [sortDescending, setSortDescending] = useState(true)
  const [columnId, setColumnId] = useState<number | undefined>(initSortKeyIndex)
  const [tableRows, setTableRows] = useState<any>([])
  const [sortKey, setSortKey] = useState<string>(initSortKey ?? '')

  useEffect(() => {
    let temp = rows?.sort((a: TableRow, b: TableRow) =>
      compare(a, b, sortKey, sortDescending ? 'desc' : 'asc')
    )
    setTableRows(temp)
  }, [rows, sortDescending, sortKey])

  const handleSortingChange = (key: string, index: number) => {
    let tempSortKey =
      Object.keys(headers).find((k) => headers[k].text === key) ?? ''

    rows?.sort((a: TableRow, b: TableRow) =>
      compare(a, b, tempSortKey, !sortDescending ? 'desc' : 'asc')
    )
    setSortKey(tempSortKey ?? '')
    setSortDescending(!sortDescending)
    setColumnId(index)
  }

  const formatRowValue = (value: string | Date | number | undefined) => {
    if (value instanceof Date) return formatDate(value)
    return value
  }

  const compare = (
    a: TableRow,
    b: TableRow,
    sortKey: string,
    order: string
  ) => {
    if (sortKey.length < 1) return 0
    let comparison = 0
    if (a.tableCell[sortKey].text < b.tableCell[sortKey].text) comparison = -1
    if (a.tableCell[sortKey].text > b.tableCell[sortKey].text) comparison = 1
    return order === 'desc' ? comparison * -1 : comparison
  }

  return (
    <div>
      <table
        className={`text-left ${
          fixedTable ? 'table-fixed' : 'table-auto'
        } w-full`}
      >
        <thead className="text-sm text-gray-700">
          <tr className="bg-white">
            {Object.values(headers).map((k, index) => {
              return (
                <th
                  key={k.text}
                  className={`py-2 px-2 sticky top-0 bg-[#f2f9ff] ${
                    k.sortable ? 'cursor-pointer' : ''
                  } sortable-header`}
                  onClick={() =>
                    k.sortable ? handleSortingChange(k.text, index) : undefined
                  }
                >
                  <div className="flex">
                    <div className="mr-2">{k.text}</div>
                    {index !== Object.values(headers).length && k.sortable && (
                      <div
                        className={`${
                          index === columnId &&
                          'bg-[#2a2859] fill-white sort-arrow '
                        }`}
                      >
                        {index === columnId ? (
                          <>
                            {sortDescending ? (
                              <ArrowDown className="sort-arrow" />
                            ) : (
                              <ArrowUp className="sort-arrow" />
                            )}
                          </>
                        ) : (
                          <ArrowDown className="sort-arrow" />
                        )}
                      </div>
                    )}
                  </div>
                </th>
              )
            })}
            {Object.values(rows).find((r) => {
              return r.htmlElement
            }) ? (
              <th className="py-2 sticky top-0 bg-[#f2f9ff]"></th>
            ) : (
              <></>
            )}
          </tr>
        </thead>
        <tbody>
          {isFetchingData ? (
            <tr>
              <td
                colSpan={Object.keys(headers).length}
                align="center"
                className="py-2"
              >
                <Loading />
              </td>
            </tr>
          ) : (
            <>
              {tableRows?.map((row: TableRow, index: number) => {
                return (
                  <tr className="text-sm text-left" key={index}>
                    {Object.keys(headers).map((header, index) => (
                      <td
                        className={`py-3 ${
                          row.cssClass ?? 'bg-white'
                        } px-2 pr-2 ${Object.values(headers)[index]?.class}`}
                        key={index}
                      >
                        <div className={row.tableCell[header]?.cssClass}>
                          {row.tableCell[header]?.iconLeftOfText ? (
                            <div className="flex">
                              {row.tableCell[header].iconLeftOfText}
                              <span className="px-2">
                                {formatRowValue(row.tableCell[header].text)}
                              </span>
                            </div>
                          ) : row.tableCell[header]?.iconRightOfText ? (
                            <div className="flex">
                              <span className="mr-2">
                                {formatRowValue(row.tableCell[header].text)}
                              </span>
                              <div
                                className="flex items-start cursor-pointer"
                                onClick={() =>
                                  row.tableCell[
                                    header
                                  ]?.htmlElementClickFunction?.(
                                    row.data,
                                    header
                                  )
                                }
                              >
                                {row.tableCell[header].iconRightOfText}
                              </div>
                            </div>
                          ) : (
                            <span>
                              {formatRowValue(row.tableCell[header]?.text)}
                            </span>
                          )}
                        </div>
                      </td>
                    ))}
                    {row.htmlElement && (
                      <td
                        data-testid={`html-element-row-${index.toString()}`}
                        className={`py-3 ${
                          row.cssClass ?? 'bg-white'
                        } cursor-pointer`}
                        onClick={() =>
                          !row.isDisabled &&
                          row.htmlElementClickFunction(row.data)
                        }
                      >
                        {row.htmlElement}
                      </td>
                    )}
                    {!row.htmlElement && row.cssClass && (
                      <td
                        data-testid={`html-element-row-${index.toString()}`}
                        className={`py-3 ${row.cssClass} cursor-pointer`}
                      ></td>
                    )}
                  </tr>
                )
              })}
            </>
          )}
        </tbody>
        {footers && (
          <tfoot className="bg-[#f2f9ff] osg-sans-bold ">
            <tr>
              {Object.values(footers).map((f, i) => (
                <td className="py-2 px-2" key={i}>
                  {f.text}
                </td>
              ))}
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  )
}
