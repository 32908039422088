import { formatDate } from '../../utils/dateTimeUtils'
import { PrintablePage } from '../common/printablePage/PrintablePage'
import { ReactComponent as Logo } from '../../assets/osgIcons/oslo-logo.svg'
import { OpprettTommerundeRader } from './OpprettTommerunde'

interface PrintableOpprettTommerundeProps {
  automater: OpprettTommerundeRader[] | undefined
  dato: Date
}

export const PrintableOpprettTommerunde = (
  props: PrintableOpprettTommerundeProps
) => {
  const { automater, dato } = props
  return (
    <PrintablePage>
      <Logo className="h-logo-large mb-5" />
      <h1 className="osg-sans-bold pb-3">{`Tømmerunde - ${
        automater && formatDate(dato)
      }`}</h1>
      <h1 className="pb-4">Bestilt av: Bymiljøetaten</h1>

      <table className="w-full table-auto">
        <thead className="text-left">
          <tr className="h-8 border-b-4 border-gray-500">
            <th>Automat</th>
            <th>Adresse</th>
            <th>Boks inn</th>
            <th>Boks ut</th>
            <th>Avvikskommentar</th>
          </tr>
        </thead>
        <tbody>
          {automater?.map((t) => (
            <tr
              key={t.automatnummer}
              className="h-8 border-b-4 border-gray-500"
            >
              <td className="border border-x-4 border-gray-500">
                {t.automatnummer}
              </td>
              <td className="border border-x-4 border-gray-500">{t.adresse}</td>
              <td className="border border-x-4 border-gray-500"></td>
              <td className="border border-x-4 border-gray-500"></td>
              <td className="border border-x-4 border-gray-500"></td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="w-full mt-5">
        Antall automater/nøkler: {automater?.length}
      </div>
      <div className="w-full mt-[4rem] flex gap-4">
        <p className="osg-sans-bold">Kvittert ut: </p>
        <hr className="mt-[1rem] w-[400px] border-2 border-gray-500" />
        <p className="osg-sans-bold">Tømt av: </p>
        <hr className="mt-[1rem] w-[400px] border-2 border-gray-500" />
      </div>

      <div className="w-full pt-10 flex"></div>
    </PrintablePage>
  )
}
