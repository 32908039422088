import "./PrintablePage.scss";

interface PrintablePageProps {
  children: React.ReactNode;
}

export const PrintablePage = (props: PrintablePageProps) => {
  const { children } = props;

  return <div className="print-me">{children}</div>;
};
