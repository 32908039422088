import { useEffect, useState } from 'react'
import { TommerundeAutomatDTO } from '../../utils/api/interfaces'
import { Table, TableCell, TableHeader, TableRow } from '../common/table/Table'
import { ReactComponent as EditIcon } from '../../assets/osgIcons/edit.svg'
import { ManuellRegistreringModal } from '../manuellRegistrering/ManuellRegistreringModal'
import { ReactComponent as MinusCircle } from '../../assets/osgIcons/minus-circle.svg'
import { ManuellRegistreringAvviksforklaringModal } from '../manuellRegistrering/ManuellRegistreringAvviksforklaringModal'
import { ManuellRegistreringBoksInnUtModal } from './ManuellRegistreringBoksInnUtModal'
import { appendZero } from '../../utils/moneyUtils'

interface AutomaterSomSkalArkiveresProps {
  rader: TommerundeAutomatDTO[]
  oppdaterListe: (action: any) => void
  isFetchingData: boolean
  kolonner: TableHeader
  tommerundeDato?: Date
  tommerundeId?: string
  tommerundeFullfort: boolean
}

export const AutomaterSomSkalArkiveres = (
  props: AutomaterSomSkalArkiveresProps
) => {
  const {
    rader,
    oppdaterListe,
    isFetchingData,
    kolonner,
    tommerundeDato,
    tommerundeId,
    tommerundeFullfort,
  } = props

  const [tableRows, setTableRows] = useState<TableRow[]>()
  const [automatToUpdate, setAutomatToUpdate] = useState<TommerundeAutomatDTO>()
  const [tableCellType, setTableCellType] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalBoksInnUtIsOpen, setModalBoksInnUtIsOpen] = useState(false)
  const [avviksforklaringModalIsOpen, setAvviksforklaringModalIsOpen] =
    useState(false)

  const changeAutomatToUpdate = (
    automat: TommerundeAutomatDTO,
    headerKey: string
  ) => {
    setAutomatToUpdate(automat)
    setTableCellType(headerKey)
    setModalIsOpen(true)
  }

  const handlePutTommerapport = (
    automat: TommerundeAutomatDTO,
    headerKey: string
  ) => {
    setAutomatToUpdate(automat)
    setTableCellType(headerKey)
    setModalBoksInnUtIsOpen(true)
  }

  const handleAddAvviksforklaring = (a: TommerundeAutomatDTO) => {
    setAutomatToUpdate(a)
    setAvviksforklaringModalIsOpen(true)
  }

  useEffect(() => {
    let tabellRader = rader?.map((a) => {
      return {
        tableCell: {
          automat: { text: a.automatnummer },
          boksUt: {
            text: a.tommerapport?.boksUt,
            iconRightOfText: <EditIcon />,
            htmlElementClickFunction: handlePutTommerapport,
          },
          boksInn: {
            text: a.tommerapport?.boksInn,
            iconRightOfText: <EditIcon />,
            htmlElementClickFunction: handlePutTommerapport,
          },
          bekreftetTommedato: {
            text: a.bekreftetTommedato ? new Date(a.bekreftetTommedato) : '',
          },
          belopBillett: {
            text: appendZero(a.tommebillett?.verdi) ?? 'Data mangler',
            iconRightOfText: !tommerundeFullfort && <EditIcon />,
            htmlElementClickFunction:
              !tommerundeFullfort && changeAutomatToUpdate,
          },
          tellesum: {
            text: appendZero(a.opptelling?.opptaltVerdi) ?? 'Data mangler',
            iconRightOfText: !tommerundeFullfort && <EditIcon />,
            htmlElementClickFunction:
              !tommerundeFullfort && changeAutomatToUpdate,
          },
          avviksum: {
            text: appendZero(a.avvikssum),
            cssClass: !automatHarRegnskapsmessigAvvik(a)
              ? 'text-gray-300'
              : undefined,
          },
          avvikskommentar: {
            text: a.avviksforklaring?.kommentar
              ? a.avviksforklaring?.kommentar
              : !tommerundeFullfort
              ? 'Legg til kommentar'
              : '',
            iconRightOfText: !tommerundeFullfort && <EditIcon />,
            htmlElementClickFunction:
              !tommerundeFullfort && handleAddAvviksforklaring,
          },
          billettNummer: {
            text: a.tommebillett?.billettnummer,
            iconRightOfText: <EditIcon />,
            htmlElementClickFunction: changeAutomatToUpdate,
          },
        } as TableCell,
        data: a,
        htmlElement: automatManglerVerdier(a) && !tommerundeFullfort && (
          <MinusCircle />
        ),
        htmlElementClickFunction:
          automatManglerVerdier(a) && !tommerundeFullfort && oppdaterListe,
      } as TableRow
    })
    let tellesumTotal = 0
    rader.forEach((x) => {
      tellesumTotal += x.opptelling?.opptaltVerdi ?? 0
    })
    tabellRader.push({
      tableCell: {
        automat: { text: rader?.length + ' stk', cssClass: 'osg-sans-bold' },
        boksUt: { text: '' },
        boksInn: { text: '' },
        belopBillett: { text: '' },
        tellesum: {
          text: appendZero(tellesumTotal),
          cssClass: 'osg-sans-bold',
        },
        avviksum: { text: '' },
        avvikskommentar: { text: '' },
      } as TableCell,
      cssClass: 'bg-[#f2f9ff]',
    } as TableRow)
    setTableRows(tabellRader)
  }, [rader, tommerundeFullfort, oppdaterListe])

  const automatManglerVerdier = (a: TommerundeAutomatDTO) => {
    return !(a.tommebillett?.verdi ?? a.opptelling)
  }

  const automatHarRegnskapsmessigAvvik = (a: TommerundeAutomatDTO) => {
    if (a.avvikssum) return !!(a.avvikssum < -50 || a.avvikssum > 50)
    else return false
  }

  return (
    <>
      <div className="border">
        <Table
          rows={tableRows ?? []}
          headers={kolonner}
          isFetchingData={isFetchingData}
          fixedTable={false}
        />
      </div>

      {modalIsOpen && automatToUpdate && tommerundeId && (
        <ManuellRegistreringModal
          automat={automatToUpdate}
          type={tableCellType}
          tommerundeId={tommerundeId}
          tommerundeDato={tommerundeDato}
          setModalIsOpen={setModalIsOpen}
        />
      )}

      {modalBoksInnUtIsOpen && automatToUpdate && tommerundeId && (
        <ManuellRegistreringBoksInnUtModal
          automat={automatToUpdate}
          tommerundeId={tommerundeId}
          setModalIsOpen={setModalBoksInnUtIsOpen}
        />
      )}

      {avviksforklaringModalIsOpen && automatToUpdate && tommerundeId && (
        <ManuellRegistreringAvviksforklaringModal
          automat={automatToUpdate}
          tommerundeId={tommerundeId}
          setModalIsOpen={setAvviksforklaringModalIsOpen}
        />
      )}
    </>
  )
}
