interface InfoHeaderProps {
  textLarge: string
  textSmall?: string
}

export const InfoHeader = ({ textLarge, textSmall }: InfoHeaderProps) => {
  return (
    <div className="pt-14 pb-7">
      <p className="osg-sans-medium text-3xl">{textLarge}</p>
      <p className="osg-sans-regular text-l py-2">{textSmall}</p>
    </div>
  )
}
