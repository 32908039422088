import { useEffect, useState } from 'react'
import {
  usePutTommerundeManuellOpptelling,
  usePutTommerundeManuellTomming,
} from '../../hooks/useTommerunder'
import {
  PutTommerundeManuellOpptellingDTO,
  PutTommerundeManuellTommingDTO,
  TommerundeAutomatDTO,
} from '../../utils/api/interfaces'
import { ManuellRegistreringAntallKroner } from './ManuellRegistreringAntallKroner'
import { ReactComponent as CalendarIcon } from '../../assets/osgIcons/calendar.svg'
import DatePicker from 'react-datepicker'

interface ManuellRegistreringModalProps {
  automat: TommerundeAutomatDTO
  type: string
  tommerundeId: string
  tommerundeDato?: Date
  setModalIsOpen: (isOpen: boolean) => void
}

export interface AntallKronerInput {
  enkroner: number
  femkroner: number
  tikroner: number
  tjuekroner: number
}

const antallKronerToSend: AntallKronerInput = {
  enkroner: 0,
  femkroner: 0,
  tikroner: 0,
  tjuekroner: 0,
}

export const ManuellRegistreringModal = (
  props: ManuellRegistreringModalProps
) => {
  const { automat, type, tommerundeId, tommerundeDato, setModalIsOpen } = props
  const [antallKroner, setAntallKroner] =
    useState<AntallKronerInput>(antallKronerToSend)

  const [registreringsdato, setRegistreringsdato] = useState(
    new Date(tommerundeDato ?? '')
  )
  const [billettnummer, setBillettnummer] = useState<number>(
    automat.tommebillett?.billettnummer ?? 0
  )

  const manuellOpptelling = usePutTommerundeManuellOpptelling()
  const manuellTomming = usePutTommerundeManuellTomming()

  useEffect(() => {
    if (type === 'tellesum' && automat.opptelling) {
      let kroner = automat.opptelling
      setAntallKroner({
        enkroner: kroner.antallEnKroner,
        femkroner: kroner.antallFemKroner,
        tikroner: kroner.antallTiKroner,
        tjuekroner: kroner.antallTjueKroner,
      })
    }

    if (
      (type === 'belopBillett' || type === 'billettNummer') &&
      automat.tommebillett
    ) {
      let kroner = automat.tommebillett
      setAntallKroner({
        enkroner: kroner.antallEnKroner,
        femkroner: kroner.antallFemKroner,
        tikroner: kroner.antallTiKroner,
        tjuekroner: kroner.antallTjueKroner,
      })
    }
  }, [automat, type])

  const sendManuellOpptelling = () => {
    if (type === 'tellesum') {
      const dataToSend: PutTommerundeManuellOpptellingDTO = {
        antallEnKroner: antallKroner.enkroner,
        antallFemKroner: antallKroner.femkroner,
        antallTiKroner: antallKroner.tikroner,
        antallTjueKroner: antallKroner.tjuekroner,
        opptaltTidspunkt: registreringsdato.toISOString(),
      }
      manuellOpptelling.mutate(
        {
          tommerundeId: tommerundeId,
          automatId: automat.automatId,
          opptellingInputModel: dataToSend,
        },
        {
          onSuccess: () => {
            setModalIsOpen(false)
          },
        }
      )
    }

    if (type === 'belopBillett' || type === 'billettNummer') {
      const dataToSend: PutTommerundeManuellTommingDTO = {
        antallEnKroner: antallKroner.enkroner,
        antallFemKroner: antallKroner.femkroner,
        antallTiKroner: antallKroner.tikroner,
        antallTjueKroner: antallKroner.tjuekroner,
        tommetidspunkt: registreringsdato.toISOString(),
        billettnummer: billettnummer,
      }
      manuellTomming.mutate(
        {
          tommerundeId: tommerundeId,
          automatId: automat.automatId,
          opptellingInputModel: dataToSend,
        },
        {
          onSuccess: () => {
            setModalIsOpen(false)
          },
        }
      )
    }
  }

  const setHeader = () => {
    if (type === 'tellesum') return 'Beløp innbetalt'
    return 'Beløp billett'
  }

  return (
    <div className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60">
      <div className="fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2 max-w-2xl bg-white px-8 py-6 space-y-5 drop-shadow-lg">
        <div className="text-center p-7">
          <h1 className="osg-sans-bold">{setHeader()}</h1>
          <p>Legg inn antall mynter for automat {automat.automatnummer}</p>
        </div>
        <div className="flex flex-col gap-8">
          {(type === 'billettNummer' || type === 'belopBillett') && (
            <div className="flex justify-between items-center">
              <label htmlFor="billettnummer" className="osg-sans-medium w-2/3">
                Endre billettnummer:
              </label>
              <input
                type="text"
                className="pkt-form-input w-40 h-12"
                id="billettnummer"
                value={billettnummer}
                onChange={(e) =>
                  setBillettnummer(parseInt(e.target.value) || 0)
                }
              />
            </div>
          )}
          <div className="flex justify-between items-center">
            <div className="osg-sans-medium">Velg dag for tømming:</div>
            <div className="flex relative items-center justify-end">
              <DatePicker
                locale="nb"
                className="pkt-form-input text-left h-12 w-40"
                selected={registreringsdato}
                dateFormat="dd.MM.yyyy"
                onChange={(date: Date) => setRegistreringsdato(date)}
              />
              <CalendarIcon className="absolute mr-2 h-5 w-5" />
            </div>
          </div>
          <ManuellRegistreringAntallKroner
            setAntallKroner={setAntallKroner}
            antallKroner={antallKroner}
          />

          <div className="flex justify-end py-10">
            <div className="border-solid border-b-[3px] border-[#2a2859] flex items-end justify-end w-1/6 mr-2">
              <p>
                {antallKroner.enkroner * 1 +
                  antallKroner.femkroner * 5 +
                  antallKroner.tikroner * 10 +
                  antallKroner.tjuekroner * 20}{' '}
              </p>
            </div>
            <div className="flex items-end mr-10 osg-sans-medium">Total</div>

            <button
              onClick={() => setModalIsOpen(false)}
              className="pkt-btn pkt-btn--secondary mr-5"
            >
              <span className="pkt-btn__text">Avbryt</span>
            </button>

            <button
              onClick={() => sendManuellOpptelling()}
              className="pkt-btn pkt-btn--medium"
            >
              <span className="pkt-btn__text">Lagre</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
