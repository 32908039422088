import { useEffect, useState } from 'react'
import { Table, TableCell, TableRow } from '../common/table/Table'
import { ReactComponent as PlusCircleNoFill} from '../../assets/osgIcons/plus-circle-no-fill.svg'

import { OpprettTommerundeTableProps } from './OpprettTommerunde'
import { SearchBox } from '../common/SearchBox'

export const Automater = (props: OpprettTommerundeTableProps) => {
  const { rader, oppdaterListe, isFetchingData, showParkeringsautomater } = props
  const [tableRows, setTableRows] = useState<TableRow[]>()

  const [searchVal, setSearchVal] = useState('')

  const headers = {
    automatnummer: { text: 'Automatnummer', sortable: true },
    adresse: { text: 'Adresse', sortable: true },
    sistTomt: { text: 'Sist tømt', sortable: true },
    myntnivaa: { text: 'Myntnivå d.d', sortable: true, initSort: true },
  }

  useEffect(() => {
    let temp = rader?.filter(
      (a) =>
        a.adresse.toString().toLowerCase().includes(searchVal.toLowerCase()) ||
        a.automatnummer
          .toString()
          .toLowerCase()
          .includes(searchVal.toLowerCase())
    )
  
    setTableRows(
      temp?.filter(a => showParkeringsautomater ? a.isParkeringsautomat : !a.isParkeringsautomat).map((a) => {
        return {
          tableCell: {
            automatnummer: { text: a.automatnummer },
            adresse: { text: a.adresse },
            sistTomt: { text: a.sistTomt ? new Date(a.sistTomt) : '' },
            myntnivaa: { text: a.myntnivaa },
          } as TableCell,
          data: a,
          isDisabled: a.finnesITommerunde,
          htmlElement: a.finnesITommerunde ? <PlusCircleNoFill className='fill-[#d9e9f2]' /> : <PlusCircleNoFill className='fill-[#2A2859]' />,
          htmlElementClickFunction: oppdaterListe
        } as TableRow
      })
    )
  }, [rader, searchVal, showParkeringsautomater, oppdaterListe])

  return (
    <>
      <SearchBox onChangeFunction={setSearchVal} />

      <div className="overflow-y-auto max-h-[30vh] border">
        <Table
          rows={tableRows ?? []}
          headers={headers}
          isFetchingData={isFetchingData}
        />
      </div>
    </>
  )
}
