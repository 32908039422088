import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  useGetTommerunde,
  usePostTommerundeFullfor,
  usePutTommerundeCerFil,
  usePutTommerundeHTFil,
  usePutTommerundeLeggAutomatTilbake,
  usePutTommerundeTaAutomatUt,
} from '../../hooks/useTommerunder'
import { TableHeader } from '../common/table/Table'
import { InfoHeader } from '../info-header/InfoHeader'
import { ReactComponent as ArrowThinDown } from '../../assets/osgIcons/chevron-right.svg'

import { StatusIcon } from '../common/StatusIcon'
import {
  AutomatTattUtAvRundeDTO,
  ImportResultatDTO,
  TommerundeAutomatDTO,
} from '../../utils/api/interfaces'
import { AutomaterSomIkkeSkalArkiveres } from './AutomaterSomIkkeSkalArkiveres'
import { AutomaterSomSkalArkiveres } from './AutomaterSomSkalArkiveres'
import { formatDate } from '../../utils/dateTimeUtils'
import { download_table_as_csv } from '../../utils/csvTableGenerator'
import { PrintableTommerunde } from './PrintableTommerunde'
import { TommerundeCSVTable } from './TommerundeCSVTable'
import { UploadFile } from '../common/UploadFile'

export const Tommerunde = () => {
  const { id } = useParams()
  const { data: tommerunde, isFetching } = useGetTommerunde(id ?? '')

  const taAutomatUt = usePutTommerundeTaAutomatUt()
  const leggAutomatTilbake = usePutTommerundeLeggAutomatTilbake()
  const fullforTommerunde = usePostTommerundeFullfor()
  const putCERFil = usePutTommerundeCerFil()
  const putHTFil = usePutTommerundeHTFil()

  const [automaterToArchive, setAutomaterToArchive] = useState<
    TommerundeAutomatDTO[]
  >([])
  const [automaterToNotArchive, setAutomaterToNotArchive] = useState<
    AutomatTattUtAvRundeDTO[]
  >([])

  const [htFil, setHTFil] = useState<File | null>(null)
  const [cerFil, setCERFil] = useState<File | null>(null)

  const kolonner = {
    automat: { text: 'Automat', sortable: false },
    boksUt: { text: 'Boks ut', sortable: false },
    boksInn: { text: 'Boks inn', sortable: false },
    bekreftetTommedato: { text: 'Bekreftet tømmedato', sortable: false },
    belopBillett: { text: 'Beløp billett', sortable: false },
    tellesum: { text: 'Innbetalt', sortable: false },
    avviksum: { text: 'Avvik', sortable: false },
    avvikskommentar: {
      text: 'Avvikskommentar',
      sortable: false,
      class: 'w-1/6',
    },
    billettNummer: { text: 'Billettnummer', sortable: false },
  } as TableHeader

  const handleFullforTommerunde = () => {
    if (tommerunde?.erKlarForBokforing) {
      fullforTommerunde.mutate(
        {
          tommerundeId: tommerunde.id,
        },
        {
          onSuccess: () => {
            window.location.reload()
          },
          onError: () => alert('Tømmerunde ble ikke sendt!'),
        }
      )
    }
  }

  const handleTaAutomatUt = (a: TommerundeAutomatDTO) => {
    taAutomatUt.mutate(
      {
        tommerundeId: tommerunde?.id ?? '',
        automatId: a.automatId,
      },
      {
        onSuccess: (r) => {
          console.log('Automat tatt ut')
        },
        onError: (e) => alert('Tømmerunde ble ikke sendt!'),
      }
    )
  }

  const handleLeggAutomatTilbake = (a: AutomatTattUtAvRundeDTO) => {
    if (tommerunde?.status !== 'Fullført') {
      leggAutomatTilbake.mutate(
        {
          tommerundeId: tommerunde?.id ?? '',
          automatId: a.automatId,
        },
        {
          onSuccess: (r) => {
            console.log('Automat lagt tilbake')
          },
          onError: (e) => alert('Tømmerunde ble ikke sendt!'),
        }
      )
    }
  }

  const handleUpdateHTFile = (file: File) => {
    setHTFil(file)
  }

  const handleUpdateCERFile = (file: File) => {
    setCERFil(file)
  }

  const handleArkiverCERFil = (e: any, id: string) => {
    e.preventDefault()
    let formData = new FormData()

    if (cerFil) {
      formData.append('fil', cerFil)

      putCERFil.mutate(
        {
          tommerundeId: id,
          fil: formData,
        },
        {
          onSuccess: ({prosesserteLinjer}) => {
            if (prosesserteLinjer.every(l => l.suksess)) {
              alert('Import vellykket');
            } else {
              alert(getImportResultatOppsummering(prosesserteLinjer))
            }
            setCERFil(null)
          },
          onError: () => {
            alert('Kunne ikke laste opp CER-fil')
          },
        }
      )
    }
  }

  const handleArkiverHTFil = (e: any, id: string) => {
    e.preventDefault()
    let formData = new FormData()

    if (htFil) {
      formData.append('fil', htFil)

      putHTFil.mutate(
        {
          tommerundeId: id,
          fil: formData,
        },
        {
          onSuccess: ({prosesserteLinjer}) => {
            if (prosesserteLinjer.every(l => l.suksess)) {
              alert('Import vellykket');
            } else {
              alert(getImportResultatOppsummering(prosesserteLinjer))
            }
            setHTFil(null)
          },
          onError: () => {
            alert('Kunne ikke laste opp HT-fil')
          },
        }
      )
    }
  }

  useEffect(() => {
    setAutomaterToArchive(tommerunde?.automater ?? [])
    setAutomaterToNotArchive(tommerunde?.automaterTattUtAvRunde ?? [])
  }, [isFetching, tommerunde?.automater, tommerunde?.automaterTattUtAvRunde])

  return (
    <>
      <div className="pt-4 -pb-4">
        <nav className="flex">
          <ul>
            <li className="inline-flex items-center">
              <Link
                to={'/tommerunder'}
                className="inline-flex items-center text-sm"
              >
                <ArrowThinDown className="h-5 w-5 mr-1 rotate-180" />
                Alle tømmerunder
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className="grid grid-cols-2">
        <InfoHeader
          textLarge={`Tømmerunde - ${
            tommerunde && formatDate(tommerunde.tommedato)
          }`}
          textSmall={`${tommerunde?.opprettetAv}`}
        />
        <div className="mb-10 flex justify-end items-end">
          <StatusIcon status={tommerunde?.status ?? ''} />
          <div className="ml-2">{tommerunde?.status ?? ''}</div>
        </div>
      </div>

      <div className="grid grid-cols-2">
        <div>
          <button
            className="mb-5 pkt-btn pkt-btn--secondary"
            onClick={() => window.print()}
          >
            Skriv ut
          </button>

          <button
            className="mb-5 pkt-btn ml-3"
            onClick={() =>
              download_table_as_csv(
                'tommerundeCSVTable',
                'Tommerunde_' +
                  new Date(tommerunde?.tommedato ?? '').toLocaleDateString('nb')
              )
            }
          >
            Last ned
          </button>
        </div>
        <div className="flex gap-5 justify-end items-end mb-5">
          <p className="mb-2 osg-sans-medium">CER-fil</p>
          <UploadFile
            tommerunde={tommerunde}
            fil={cerFil}
            setFil={handleUpdateCERFile}
          />

          <button
            className="pkt-btn pkt-btn--primary"
            onClick={(e) => handleArkiverCERFil(e, tommerunde?.id ?? '')}
            disabled={!cerFil}
          >
            Last opp
          </button>
        </div>
      </div>
      <div className="flex gap-5 justify-end items-end mb-5">
        <p className="mb-2 osg-sans-medium">HT-fil</p>
        <UploadFile
          tommerunde={tommerunde}
          fil={htFil}
          setFil={handleUpdateHTFile}
        />

        <button
          className="pkt-btn pkt-btn--primary"
          onClick={(e) => handleArkiverHTFil(e, tommerunde?.id ?? '')}
          disabled={!htFil}
        >
          Last opp
        </button>
      </div>

      <div className="border" id="automaterSomSkalArkiveres">
        <AutomaterSomSkalArkiveres
          rader={automaterToArchive}
          oppdaterListe={handleTaAutomatUt}
          kolonner={kolonner}
          isFetchingData={isFetching}
          tommerundeDato={tommerunde?.tommedato}
          tommerundeId={tommerunde?.id}
          tommerundeFullfort={tommerunde?.status === 'Fullført' ?? false}
        />
      </div>

      <div className="pt-14 pb-7">
        <p className="osg-sans-medium text-xl">
          Ikke tømte automater - {automaterToNotArchive.length} stk
        </p>
      </div>

      <div className="border">
        <AutomaterSomIkkeSkalArkiveres
          rader={automaterToNotArchive}
          oppdaterListe={handleLeggAutomatTilbake}
          kolonner={kolonner}
          isFetchingData={isFetching}
          erFullfort={tommerunde?.status === 'Fullført'}
        />
      </div>

      <div className="flex justify-end py-10 gap-4">
        <button
          onClick={() => handleFullforTommerunde()}
          disabled={
            !tommerunde?.erKlarForBokforing || tommerunde?.status === 'Fullført'
          }
          className={`${
            tommerunde?.status === 'Fullført' && 'hidden'
          } pkt-btn pkt-btn--medium`}
        >
          <span className="pkt-btn__text">Arkiver inntektsrapport</span>
        </button>
      </div>

      <PrintableTommerunde tommerunde={tommerunde} />
      <div className="hidden">
        <TommerundeCSVTable tommerunde={tommerunde} />
      </div>
    </>
  )
}

function getImportResultatOppsummering(prosesserteLinjer: ImportResultatDTO['prosesserteLinjer']) {
  const feilet = prosesserteLinjer.filter(l => !l.suksess)

  return `Import vellykket for ${prosesserteLinjer.length - feilet.length} av `
    + `${ prosesserteLinjer.length } linjer.\n`
    + 'Import feilet for linjer:\n'
    + feilet.map(l => `linje ${l.linje}: ${l.beskjed}\n`)
      .join('')
}