import { useNavigate } from 'react-router-dom'
import { usePostTommerunde } from '../../hooks/useTommerunder'
import { OpprettTommerundeRader } from './OpprettTommerunde'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { registerLocale } from 'react-datepicker'
import { useState } from 'react'

import { ReactComponent as CalendarIcon } from '../../assets/osgIcons/calendar.svg'

import nb from 'date-fns/locale/nb'
import { PrintableOpprettTommerunde } from './PrintableOpprettTommerunde'
import { Toggle } from '../common/Toggle'
registerLocale('nb', nb)

interface OpprettTommerundeModalProps {
  automater: OpprettTommerundeRader[]
  setModalIsOpen: (isOpen: boolean) => void
}

export const OpprettTommerundeModal = (props: OpprettTommerundeModalProps) => {
  const { automater, setModalIsOpen } = props
  const [tommedato, setTommedato] = useState(new Date())
  const [harSkrevetUt, setHarSkrevetUt] = useState(false)

  const [ikkeSendBestilling, setIkkeSendBestilling] = useState(false)

  const opprettTommerunde = usePostTommerunde()
  let navigate = useNavigate()

  const handleSkrivUt = () => {
    window.print()
    setHarSkrevetUt(true)
  }

  const sendTommerunde = (automaterSomSkalSendes: OpprettTommerundeRader[]) => {
    let automatIderTilTomming = automaterSomSkalSendes.map((a) => a.automatid)
    opprettTommerunde.mutate(
      {
        automatIderTilTomming: automatIderTilTomming,
        tommedato: tommedato,
        ikkeSendBestilling: ikkeSendBestilling,
      },
      {
        onSuccess: (r) => {
          let path = `/tommerunde/${r.id}`
          navigate(path)
        },
        onError: (e) => alert('Tømmerunde ble ikke sendt!'),
      }
    )
  }

  return (
    <>
      <div className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60"></div>
      <div
        id="tommerundeModal"
        className="fixed z-50 top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 w-2/6 bg-white px-8 py-6 space-y-5 drop-shadow-lg"
      >
        <p>
          En liste med {automater.length} automater er klar for videresending
          til Nokas.
        </p>
        <div className="flex items-center">
          <div className="osg-sans-medium">Velg dag for tømming:</div>
          <div className="pl-4 pr-4">
            <CalendarIcon className="h-8 w-8" />
          </div>
          <div className="w-1/3">
            <DatePicker
              locale="nb"
              className="pkt-form-input text-center h-10"
              selected={tommedato}
              dateFormat="dd.MM.yyyy"
              onChange={(date: Date) => setTommedato(date)}
            />
          </div>
        </div>
        <div className="flex justify-center">
          <Toggle
            leftText="Automatisk bestilling"
            rightText="Manuell bestilling"
            toggleFunction={() => setIkkeSendBestilling(!ikkeSendBestilling)}
          />
        </div>

        <div className="flex justify-center gap-3">
          <button
            onClick={() => setModalIsOpen(false)}
            className="pkt-btn pkt-btn--secondary"
          >
            <span className="pkt-btn__text">Avbryt</span>
          </button>

          <button
            className="pkt-btn pkt-btn--secondary"
            onClick={handleSkrivUt}
          >
            Skriv ut
          </button>

          <button
            disabled={!harSkrevetUt}
            onClick={() => sendTommerunde(automater ?? [])}
            className="pkt-btn pkt-btn--medium"
          >
            <span className="pkt-btn__text">Send</span>
          </button>
        </div>
      </div>
      <PrintableOpprettTommerunde automater={automater} dato={tommedato} />
    </>
  )
}
