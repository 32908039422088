import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getAutomatTomminger,
  getTommerunde,
  getTommerunder,
  postTommerunde,
  postTommerundeFullfor,
  putCerFilTommerunde,
  putHTFilTommerunde,
  putTommerapport,
  putTommerundeAutomatAvviksforklaring,
  putTommerundeLeggAutomatTilbake,
  putTommerundeManuellOpptelling,
  putTommerundeManuellTomming,
  putTommerundeTaAutomatUt,
} from '../utils/api/ApiService'
import {
  AutomatTommerapportDTO,
  PutTommerundeManuellOpptellingDTO,
  PutTommerundeManuellTommingDTO,
} from '../utils/api/interfaces'

export const useGetTommerunder = (start: string, slutt: string) =>
  useQuery(['tommerunder'], () =>
    getTommerunder(start, slutt).then((r) => r.data)
  )

export const useGetTommerunde = (id: string) =>
  useQuery(['tommerunde'], () => getTommerunde(id).then((r) => r.data))

export const useGetAutomatTomminger = (
  start: Date,
  slutt: Date,
  automatNummer?: string,
  automatType?: string,
  boksInn?: number,
  boksUt?: number
) =>
  useQuery(['automatTomminger'], () =>
    getAutomatTomminger(start, slutt, automatNummer, automatType, boksInn, boksUt).then((r) => r.data)
  )

export const usePostTommerunde = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: { automatIderTilTomming: string[]; tommedato: Date; ikkeSendBestilling: boolean }) =>
      postTommerunde(variables.automatIderTilTomming, variables.tommedato, variables.ikkeSendBestilling).then(
        (r) => r.data
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tommerunder'])
        queryClient.invalidateQueries(['automater'])
      },
    }
  )
}

export const usePutTommerundeManuellOpptelling = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: {
      tommerundeId: string
      automatId: string
      opptellingInputModel: PutTommerundeManuellOpptellingDTO
    }) =>
      putTommerundeManuellOpptelling(
        variables.tommerundeId,
        variables.automatId,
        variables.opptellingInputModel
      ).then((r) => r.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tommerunde'])
        queryClient.invalidateQueries(['automater'])
      },
    }
  )
}

export const usePutTommerundeManuellTomming = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: {
      tommerundeId: string
      automatId: string
      opptellingInputModel: PutTommerundeManuellTommingDTO
    }) =>
      putTommerundeManuellTomming(
        variables.tommerundeId,
        variables.automatId,
        variables.opptellingInputModel
      ).then((r) => r.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tommerunde'])
        queryClient.invalidateQueries(['automater'])
      },
    }
  )
}

export const usePutTommerundeTaAutomatUt = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: { tommerundeId: string; automatId: string }) =>
      putTommerundeTaAutomatUt(
        variables.tommerundeId,
        variables.automatId
      ).then((r) => r.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tommerunde'])
        queryClient.invalidateQueries(['automater'])
      },
    }
  )
}

export const usePutTommerundeLeggAutomatTilbake = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: { tommerundeId: string; automatId: string }) =>
      putTommerundeLeggAutomatTilbake(
        variables.tommerundeId,
        variables.automatId
      ).then((r) => r.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tommerunde'])
        queryClient.invalidateQueries(['automater'])
      },
    }
  )
}

export const usePutTommerundeAutomatAvviksforklaring = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: {
      tommerundeId: string
      automatId: string
      forklaring: string
    }) =>
      putTommerundeAutomatAvviksforklaring(
        variables.tommerundeId,
        variables.automatId,
        variables.forklaring
      ).then((r) => r.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tommerunde'])
        queryClient.invalidateQueries(['automater'])
      },
    }
  )
}

export const usePutTommerundeCerFil = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: { tommerundeId: string; fil: FormData }) =>
      putCerFilTommerunde(variables.tommerundeId, variables.fil)
        .then((r) => r.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tommerunde'])
      },
    }
  )
}

export const usePutTommerundeHTFil = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: { tommerundeId: string; fil: FormData }) =>
      putHTFilTommerunde(variables.tommerundeId, variables.fil)
        .then((r) => r.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tommerunde'])
      },
    }
  )
}

export const usePostTommerundeFullfor = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: { tommerundeId: string }) =>
      postTommerundeFullfor(variables.tommerundeId).then((r) => r.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tommerunde'])
        queryClient.invalidateQueries(['automater'])
      },
    }
  )
}

export const usePutTommerapport = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (variables: {
      tommerundeId: string
      automatId: string
      data: AutomatTommerapportDTO
    }) =>
      putTommerapport(
        variables.tommerundeId,
        variables.automatId,
        variables.data
      ).then((r) => r.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['tommerunde'])
      },
    }
  )
}
