import { useState } from 'react'
import { usePutTommerapport } from '../../hooks/useTommerunder'
import {
  AutomatTommerapportDTO,
  TommerundeAutomatDTO,
} from '../../utils/api/interfaces'

interface ManuellRegistreringBoksInnUtModalProps {
  automat: TommerundeAutomatDTO
  tommerundeId: string
  setModalIsOpen: (isOpen: boolean) => void
}

export const ManuellRegistreringBoksInnUtModal = (
  props: ManuellRegistreringBoksInnUtModalProps
) => {
  const { automat, tommerundeId, setModalIsOpen } = props
  const [boksUt, setBoksUt] = useState<number>(
    automat.tommerapport?.boksUt ?? 0
  )
  const [boksInn, setBoksInn] = useState<number>(
    automat.tommerapport?.boksInn ?? 0
  )

  const putTommerapport = usePutTommerapport()

  const sendTommerapport = () => {
    let dataToSend: AutomatTommerapportDTO = {
      boksUt: boksUt,
      boksInn: boksInn,
      tilbakemelding: automat.tommerapport?.tilbakemelding ?? '',
    }

    putTommerapport.mutate(
      {
        tommerundeId: tommerundeId,
        automatId: automat.automatId,
        data: dataToSend,
      },
      {
        onSuccess: () => {
          setModalIsOpen(false)
        },
      }
    )
  }

  return (
    <div className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60">
      <div className="fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4/12 bg-white px-8 py-6 space-y-5 drop-shadow-lg">
        <div className="text-center p-7">
          <h1 className="osg-sans-bold">Boks ut/inn</h1>
          <p>Legg til boks ut/inn for automat {automat.automatnummer}</p>
        </div>

        <div>
          <div className="grid grid-cols-2 gap-10">
            <div>
              <label htmlFor="boksUt" className="block pkt-form-label">
                Boks ut
              </label>
              <input
                type="text"
                className="pkt-form-input"
                id="boksUt"
                value={boksUt}
                onChange={(e) => setBoksUt(parseInt(e.target.value) || 0)}
              />
            </div>
            <div className="">
              <label htmlFor="boksInn" className="block pkt-form-label">
                Boks inn
              </label>
              <input
                type="text"
                className="pkt-form-input"
                id="boksInn"
                value={boksInn}
                onChange={(e) => setBoksInn(parseInt(e.target.value) || 0)}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end py-10">
          <button
            onClick={() => setModalIsOpen(false)}
            className="pkt-btn pkt-btn--secondary mr-5"
          >
            <span className="pkt-btn__text">Avbryt</span>
          </button>

          <button
            onClick={() => sendTommerapport()}
            className="pkt-btn pkt-btn--medium"
          >
            <span className="pkt-btn__text">Lagre</span>
          </button>
        </div>
      </div>
    </div>
  )
}
