import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import { AuthProvider } from 'react-oidc-context'
import { QueryClient, QueryClientProvider } from 'react-query'
import { oidcConfig } from './utils/api/client'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

ReactDOM.render(
  <AuthProvider {...oidcConfig}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>
  </AuthProvider>,
  document.getElementById('root')
)
