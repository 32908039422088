import { GetTommerundeDTO } from '../../utils/api/interfaces'

interface TommerundeCSVTableProps {
  tommerunde: GetTommerundeDTO | undefined
}

export const TommerundeCSVTable = (props: TommerundeCSVTableProps) => {
  const { tommerunde } = props
  return (
    <table id="tommerundeCSVTable">
      <thead>
        <tr>
          <th>Automat</th>
          <th>Boks ut</th>
          <th>Boks inn</th>
          <th>Belop billett</th>
          <th>Innbetalt</th>
          <th>Avvik</th>
          <th>Avvikskommentar</th>
        </tr>
      </thead>
      <tbody>
        {tommerunde?.automater.map((a) => (
          <tr key={a.automatnummer}>
            <td>{a.automatnummer}</td>
            <td>{a.tommerapport?.boksUt ?? ""}</td>
            <td>{a.tommerapport?.boksInn ?? ""}</td>
            <td>{a.tommebillett?.verdi}</td>
            <td>{a.opptelling?.opptaltVerdi}</td>
            <td>{a.avvikssum}</td>
            <td>{a.avviksforklaring?.kommentar ?? ""}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
