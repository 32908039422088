import { useAuth } from 'react-oidc-context'
import { useLocation } from 'react-router-dom'
import forsidebilde from '../../assets/images/forsidebilde2.png'

interface LocationState {
  from: {
    pathname: string
  }
}

export const Forside = () => {
  const auth = useAuth()
  const location = useLocation();

  const locationState = location.state as LocationState

  const handleLogin = () => {
    auth.signinRedirect({state: locationState})
  }

  return (
    <div className="relative flex justify-center min-h-screen pt-16">
      <div className="absolute w-96 h-96 left-50">
        <img alt='' src={forsidebilde}></img>
        <p className="osg-sans-bold text-center">
          Velkommen til saksbehandlingssystemet for tømming av parkeringsutstyr.
          Vennligst logg inn for å få tilgang.{' '}
        </p>
        <div className="flex justify-center py-7">
          <button className="pkt-btn" onClick={handleLogin}>
            Logg inn
          </button>
        </div>
      </div>
    </div>
  )
}
