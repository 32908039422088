import axios from "axios";
import { User } from "oidc-client-ts";

export const getHostFromEnv = (url_name: string): string => {
  const key = `REACT_APP_${url_name}`;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const host = (window as any)[key] || process.env[key];
  if (host != null) return host;
  throw new Error(`Missing environment configuration with key '${key}'`);
};

export const oidcConfig = {
  authority: getHostFromEnv("ONELOGIN_AUTHORITY"),
  client_id: getHostFromEnv("ONELOGIN_CLIENT_ID"),
  redirect_uri: getHostFromEnv("ONELOGIN_REDIRECT_URI"),
  silent_redirect_uri: getHostFromEnv("ONELOGIN_SILENT_REDIRECT_URI"),
  post_logout_redirect_uri: getHostFromEnv("ONELOGIN_POST_LOGOUT_REDIRECT_URI"),
  automaticSilentRenew: true,
  scope: "openid groups params",
  response_type: "code"
};

export const getUser = () => {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
};

export const apiClient = () => axios.create({ baseURL: getHostFromEnv("URL_BACKEND") + "/api/" });
