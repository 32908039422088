export const formatDateTime = (dateTime: Date) => {
    return `${new Date(dateTime).toLocaleDateString('nb-NO', {
        dateStyle: 'medium',
      })} - ${new Date(dateTime).toLocaleTimeString('nb-NO')}`
}

export const formatDate = (dateTime: Date) => {
  return `${new Date(dateTime).toLocaleDateString('nb-NO', {
      dateStyle: 'medium',
    })}`
}

export const formatDateForCsv = (dateTime: Date) => {
  const curr = `${new Date(dateTime).toLocaleDateString('nb-NO', {
    dateStyle: 'medium',
  })}`

  return curr.replaceAll(". ", "_");
}