import { useQuery } from 'react-query'
import { getAutomat, getAutomater, getHistoriskeAutomater } from '../utils/api/ApiService'

export const useGetAutomater = () =>
  useQuery(['automater'], () => getAutomater().then((r) => r.data))

export const useGetHistoriskeAutomater = () =>
  useQuery(['historiskeAutomater'], () => getHistoriskeAutomater().then((r) => r.data))

export const useGetAutomat = (id: string, start: string, slutt: string) =>
  useQuery(['automat'], () => getAutomat(id, start, slutt).then((r) => r.data))
