import { useEffect, useState } from 'react'
import { useGetAutomatTomminger } from '../../hooks/useTommerunder'
import { download_table_as_csv } from '../../utils/csvTableGenerator'
import { appendZero } from '../../utils/moneyUtils'
import { AutomatHistorikkSok } from '../automat/AutomatHistorikkSok'
import { Table, TableCell, TableRow } from '../common/table/Table'
import { InfoHeader } from '../info-header/InfoHeader'
import { endOfDay, startOfDay, sub } from 'date-fns'
import { AutomatTommingerCSVTable } from './AutomatTommingerCSVTable'
import { useSearchParams } from 'react-router-dom'

export interface DateSearchQueryFilter {
  fra: Date
  til: Date
  automatnummer: string
  boksInn: number | undefined
  boksUt: number | undefined
  automatType: string
}

export const AutomatTomminger = () => {
  const trettiDagerSiden = startOfDay(sub(new Date(), { days: 30 }))
  const iDag = endOfDay(new Date())
  const [searchParams, setSearchParams] = useSearchParams()

  const fraDatoQueryParam = new Date(searchParams.get('fra') ?? '')
  const tilDatoQueryParam = new Date(searchParams.get('til') ?? '')
  const sokQueryParam = searchParams.get('sok') ?? ''

  const parseSearchParamToNumber = (param: string | null) => {
    return param !== null ? parseInt(param) : undefined
  }

  const initialValues: DateSearchQueryFilter = {
    fra: isNaN(fraDatoQueryParam.getDate())
      ? trettiDagerSiden
      : fraDatoQueryParam,
    til: isNaN(tilDatoQueryParam.getDate()) ? iDag : tilDatoQueryParam,
    automatnummer: sokQueryParam,
    boksInn: parseSearchParamToNumber(searchParams.get('boksInn')),
    boksUt: parseSearchParamToNumber(searchParams.get('boksUt')),
    automatType: searchParams.get('automatType') ?? '',
  }

  const [tableRows, setTableRows] = useState<TableRow[]>()
  const [filterState, setFilterState] =
    useState<DateSearchQueryFilter>(initialValues)

  const {
    data: automater,
    refetch,
    isLoading,
  } = useGetAutomatTomminger(
    filterState.fra,
    filterState.til,
    filterState.automatnummer,
    filterState.automatType,
    filterState.boksInn,
    filterState.boksUt
  )

  const handleSubmitDate = () => {
    refetch()
  }

  const headers = {
    automatnummer: { text: 'Automatnr', sortable: true, initSort: true },
    tommedato: { text: 'Tømmedato', sortable: true },
    boksUt: { text: 'Boks ut', sortable: true },
    boksInn: { text: 'Boks inn', sortable: true },
    belopBillett: { text: 'Beløp billett', sortable: false },
    innbetalt: { text: 'Innbetalt', sortable: false },
    avvik: { text: 'Avvik', sortable: false },
    avviksommentar: { text: 'Avvikskommentar', sortable: false },
    billettnummer: { text: 'Billettnummer', sortable: true },
  }

  const footers = {
    automatnummer: { text: 'Antall: ' + automater?.automatTomminger.length },
    tommedato: { text: '' },
    boksUt: { text: '' },
    boksInn: { text: '' },
    belopBillett: { text: '' },
    innbetalt: { text: appendZero(automater?.opptaltTotal ?? 0) + ' kr' },
    avvik: { text: '' },
    avviksommentar: { text: '' },
    billettnummer: { text: '' },
  }

  const nullstillFilter = async () => {
    setFilterState({
      ...filterState,
      fra: trettiDagerSiden,
      til: iDag,
      automatnummer: '',
      boksInn: undefined,
      boksUt: undefined,
    })
    searchParams.delete('fra')
    searchParams.delete('til')
    searchParams.delete('sok')
    searchParams.delete('boksInn')
    searchParams.delete('boksUt')
    searchParams.delete('automatType')
    setSearchParams(searchParams)
    refetch()
  }

  const handleUpdateFraDato = (fra: Date) => {
    setFilterState((prev) => ({
      ...prev,
      fra: fra,
    }))
    searchParams.set('fra', fra.toISOString())
    setSearchParams(searchParams)
  }

  const handleUpdateTilDato = (til: Date) => {
    setFilterState((prev) => ({
      ...prev,
      til: til,
    }))

    searchParams.set('til', til.toISOString())
    setSearchParams(searchParams)
  }

  const handleUpdateSok = (key: keyof DateSearchQueryFilter, value: string) => {
    setFilterState((prev) => ({
      ...prev,
      [key]: value,
    }))

    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  const handleUpdateAutomatType = (value: string) => {
    setFilterState((prev) => ({
      ...prev,
      automatType: value,
    }))

    value === '0'
      ? searchParams.delete('automatType')
      : searchParams.set('automatType', value)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    const tabellRader = automater?.automatTomminger.map((t) => {
      return {
        tableCell: {
          automatnummer: { text: t.automatnummer },
          tommedato: {
            text: t.tommebillett?.tommetidspunkt
              ? new Date(t.tommebillett?.tommetidspunkt)
              : '',
          },
          boksUt: { text: t.tommerapport?.boksUt },
          boksInn: { text: t.tommerapport?.boksInn },
          belopBillett: { text: appendZero(t.tommebillett?.verdi ?? 0) },
          innbetalt: { text: appendZero(t.opptelling?.opptaltVerdi ?? 0) },
          avvik: {
            text: appendZero(t.avvikssum ?? 0),
            cssClass: avvikSkalIgnoreres(t.avvikssum ?? 0)
              ? 'text-gray-300'
              : '',
          },
          avviksommentar: { text: t.avviksforklaring?.kommentar },
          billettnummer: { text: t.tommebillett?.billettnummer },
        } as TableCell,
        data: t,
        htmlElementClickFunction: () => {},
      } as TableRow
    })

    setTableRows(tabellRader)
  }, [automater])

  const avvikSkalIgnoreres = (sum: number) => Math.abs(sum) <= 50

  return (
    <>
      <InfoHeader textLarge="Tømminger for alle automater i periode" />

      <div className="flex items-center gap-5 mb-4">
        <div>
          <label htmlFor="sokAutomatnummer" className="pkt-form-label">
            Automatnummer
          </label>
          <input
            name="sokAutomatnummer"
            className="pkt-form-input text-left h-10 w-40"
            value={filterState.automatnummer}
            onChange={(e) => handleUpdateSok('automatnummer', e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="sokAutomatType" className="pkt-form-label">
            Automattype
          </label>
          <select
            className="h-10"
            name="sokAutomatType"
            onChange={(e) => handleUpdateAutomatType(e.target.value)}
          >
            <option value="">Alle</option>
            <option value="1">Parkeringsautomat</option>
            <option value="2">Piggdekkautomat</option>
          </select>
        </div>
        <div>
          <label htmlFor="sokBoksUt" className="pkt-form-label">
            Boks ut
          </label>
          <input
            name="sokBoksUt"
            type="number"
            className="pkt-form-input text-left h-10 w-32 no-arrow-input"
            value={filterState.boksUt ?? ''}
            onChange={(e) => handleUpdateSok('boksUt', e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="sokBoksInn" className="pkt-form-label">
            Boks inn
          </label>
          <input
            name="sokBoksInn"
            type="number"
            className="pkt-form-input text-left h-10 w-32 no-arrow-input"
            value={filterState.boksInn ?? ''}
            onChange={(e) => handleUpdateSok('boksInn', e.target.value)}
          />
        </div>
        <div>
          <AutomatHistorikkSok
            fraDato={filterState.fra}
            tilDato={filterState.til}
            legendText=""
            updateFraDato={handleUpdateFraDato}
            updateTilDato={handleUpdateTilDato}
            handleSubmitDate={handleSubmitDate}
            nullstill={nullstillFilter}
          />
        </div>
      </div>

      <button
        className="mb-5 pkt-btn"
        onClick={() =>
          download_table_as_csv(
            'automatTommingerCSVTable',
            'Automattømminger' +
              '_' +
              filterState.fra.toLocaleDateString('nb') +
              '-' +
              filterState.til.toLocaleDateString('nb')
          )
        }
      >
        Last ned
      </button>

      <div className="border">
        <Table
          rows={tableRows ?? []}
          headers={headers}
          footers={footers}
          fixedTable={true}
          isFetchingData={isLoading}
        />
      </div>

      <div className="hidden">
        {<AutomatTommingerCSVTable automat={automater} />}
      </div>
    </>
  )
}
