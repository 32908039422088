import { Navigate } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'

interface UserState {
  from: {
    pathname: string
    search: string
  }
}

export const RedirectRoute = () => {
  const auth = useAuth();
  const userState = auth.user?.state as UserState;

  return <Navigate to={!!userState ? userState.from.pathname + userState.from.search : '/opprett-tommerunde'} />
}
