import { useState } from 'react'
import { usePutTommerundeAutomatAvviksforklaring } from '../../hooks/useTommerunder'
import { TommerundeAutomatDTO } from '../../utils/api/interfaces'

interface ManuellRegistreringModalProps {
  automat: TommerundeAutomatDTO
  tommerundeId: string
  setModalIsOpen: (isOpen: boolean) => void
}

export const ManuellRegistreringAvviksforklaringModal = (
  props: ManuellRegistreringModalProps
) => {
  const { automat, tommerundeId, setModalIsOpen } = props
  const leggTilAvviksforklaring = usePutTommerundeAutomatAvviksforklaring()
  const [avviksforklaring, setAvviksforklaring] = useState(automat.avviksforklaring?.kommentar ?? "");

  const sendAvviksforklaring = () => {
    leggTilAvviksforklaring.mutate(
      {
        tommerundeId: tommerundeId ?? '',
        automatId: automat.automatId,
        forklaring: avviksforklaring
      },
      {
        onSuccess: (r) => {
          setModalIsOpen(false)
        },
        onError: (e) => alert('Tømmerunde ble ikke sendt!'),
      }
    )
  }

  return (
    <div className="fixed z-40 w-screen h-screen inset-0 bg-gray-900 bg-opacity-60">
      <div className="fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-4/12 bg-white px-8 py-6 space-y-5 drop-shadow-lg">
        <div className="text-center p-7">
          <h1 className="osg-sans-bold">Avvikskommentar</h1>
          <p>Legg inn avvikskommentar for automat {automat.automatnummer}</p>
        </div>
        <div className='col-span-2'>
          <label htmlFor="1krInput" className="block pkt-form-label">
            Forklaring
          </label>
          <input
            type="text"
            className="pkt-form-input"
            value={avviksforklaring}
            onChange={(e) =>
              setAvviksforklaring(e.target.value)
            }
          />
        </div>
        <div className="flex justify-end py-10">
          <button
            onClick={() => setModalIsOpen(false)}
            className="pkt-btn pkt-btn--secondary mr-5"
          >
            <span className="pkt-btn__text">Avbryt</span>
          </button>

          <button
            onClick={() => sendAvviksforklaring()}
            className="pkt-btn pkt-btn--medium"
          >
            <span className="pkt-btn__text">Lagre</span>
          </button>
        </div>
      </div>
    </div>
  )
}
