import { GetAutomatTommingerDTO } from '../../utils/api/interfaces'

interface AutomatCSVTableProps {
  automat: GetAutomatTommingerDTO | undefined
}

export const AutomatTommingerCSVTable = (props: AutomatCSVTableProps) => {
  const { automat } = props
  return (
    <table id="automatTommingerCSVTable">
      <thead>
        <tr>
          <th>Automatnummer</th>
          <th>Tommedato</th>
          <th>Boks ut</th>
          <th>Boks inn</th>
          <th>Belop billett</th>
          <th>Innbetalt</th>
          <th>Avvik</th>
          <th>Avvikskommentar</th>
          <th>Billettnummer</th>
        </tr>
      </thead>
      <tbody>
        {automat?.automatTomminger.map((a, index) => (
          <tr key={a.automatId + index}>
            <td>{a.automatnummer}</td>
            <td>
              {a.tommebillett?.tommetidspunkt
                ? new Date(a.tommebillett.tommetidspunkt).toLocaleDateString(
                    'nb'
                  )
                : ''}
            </td>
            <td>{a.tommerapport?.boksUt ?? ''}</td>
            <td>{a.tommerapport?.boksInn ?? ''}</td>
            <td>{a.tommebillett?.verdi ?? 0}</td>
            <td>{a.opptelling?.opptaltVerdi ?? 0}</td>
            <td>{a.avvikssum ?? 0}</td>
            <td>{a.avviksforklaring?.kommentar ?? ''}</td>
            <td>{a.tommebillett?.billettnummer ?? ''}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
