import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker'
import { ReactComponent as CalendarIcon } from '../../assets/osgIcons/calendar.svg'
import { ReactComponent as SearchIconFilled } from '../../assets/osgIcons/search-icon-filled.svg'
import { Controller, useForm } from 'react-hook-form'

import nb from 'date-fns/locale/nb'
registerLocale('nb', nb)

interface AutomatHistorikkSokProps {
  fraDato: Date
  tilDato: Date
  legendText: string
  updateFraDato: (dato: Date) => void
  updateTilDato: (dato: Date) => void
  handleSubmitDate: () => void
  nullstill: () => void
}

export const AutomatHistorikkSok = (props: AutomatHistorikkSokProps) => {
  const {
    fraDato,
    tilDato,
    legendText,
    updateFraDato,
    updateTilDato,
    handleSubmitDate,
    nullstill,
  } = props

  const { control, getValues, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false,
    shouldFocusError: false,
  })

  const formHasErrors = () => {
    return (getValues().historikkTilDato < getValues().historikkFraDato) || tilDato < fraDato
  }
  return (
    <form className="pkt-form">
      <fieldset className="pkt-fieldset">
        <legend className="osg-sans-medium pb-5">{legendText}</legend>
        <div className="flex space-x-5">
          <div className="pkt-form-group">
            <label htmlFor="historikkFraDato" className="pkt-form-label">
              Fra dato
            </label>
            <Controller
              control={control}
              name="historikkFraDato"
              render={({ field: { onChange } }) => (
                <div className="flex relative items-center justify-end">
                  <DatePicker
                    locale="nb"
                    className="pkt-form-input text-left h-10 w-40"
                    selected={fraDato}
                    onChange={(date: Date) => {
                      onChange(date)
                      updateFraDato(date)
                      setValue("historikkFraDato", date, {shouldValidate: true})
                    }}
                    dateFormat="dd.MM.yyyy"
                  />
                  <CalendarIcon className="absolute mr-2 h-5 w-5" />
                </div>
              )}
            />
          </div>

          <div className="pkt-form-group">
            <label htmlFor="historikkTilDato" className="pkt-form-label">
              Til dato
            </label>

            <Controller
              control={control}
              name="historikkTilDato"
              render={({ field: { onChange } }) => (
                <>
                  <div className="flex relative items-center justify-end">
                    <DatePicker
                      locale="nb"
                      className="pkt-form-input text-left h-10 w-40"
                      selected={tilDato}
                      onChange={(date: Date) => {
                        onChange(date)
                        updateTilDato(date)
                        setValue("historikkTilDato", date, {shouldValidate: true})
                      }}
                      dateFormat="dd.MM.yyyy"
                    />
                    <CalendarIcon className="absolute mr-2 h-5 w-5" />
                  </div>
                  <div
                    className={`${
                      !formHasErrors() ? 'hidden' : ''
                    }  pkt-form-validation pkt-form-validation--error w-40 text-sm`}
                  >
                    {formHasErrors() && 'Til dato er før fra dato'}
                  </div>
                </>
              )}
            />
          </div>
          <div className="pt-6">
            <SearchIconFilled
              className={`h-10 w-10 ${
                formHasErrors()
                  ? 'fill-gray-500'
                  : 'cursor-pointer fill-[#2A2859]'
              } `}
              onClick={() => !formHasErrors() && handleSubmitDate()}
            />
          </div>
          <p
            className="underline pt-10 cursor-pointer h-10"
            onClick={nullstill}
          >
            Nullstill filtrering
          </p>
        </div>
      </fieldset>
    </form>
  )
}
