import { ReactNode } from 'react'
import { Header2 } from '../header/Header2'
import { ScrollToTop } from './ScrollToTop'

export const Layout = (props: { children: ReactNode | ReactNode[] }) => {
  return (
    <ScrollToTop>
      <Header2 />
      <div className="px-10">{props.children}</div>
    </ScrollToTop>
  )
}
