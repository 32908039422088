import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGetAutomat } from '../../hooks/useAutomater'
import { download_table_as_csv } from '../../utils/csvTableGenerator'
import { formatDate } from '../../utils/dateTimeUtils'
import { appendZero } from '../../utils/moneyUtils'
import { StatusIcon } from '../common/StatusIcon'
import { Table, TableCell, TableRow } from '../common/table/Table'
import { InfoHeader } from '../info-header/InfoHeader'
import { AutomatCSVTable } from './AutomatCSVTable'
import { AutomatHistorikkSok } from './AutomatHistorikkSok'
import { Automatinformasjon } from './Automatinformasjon'

export interface DateQueryFilter {
  fra: Date
  til: Date
}

export const Automat = () => {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const dagensDato = new Date()
  const dato180DagerBak = new Date(
    dagensDato.setDate(dagensDato.getDate() - 180)
  )

  const fraDatoQueryParam = new Date(searchParams.get('fra') ?? '')
  const tilDatoQueryParam = new Date(searchParams.get('til') ?? '')

  const initialValues: DateQueryFilter = {
    fra: isNaN(fraDatoQueryParam.getDate())
      ? dato180DagerBak
      : fraDatoQueryParam,
    til: isNaN(tilDatoQueryParam.getDate()) ? new Date() : tilDatoQueryParam,
  }

  const [dato, setDato] = useState<DateQueryFilter>(initialValues)
  const [tableRows, setTableRows] = useState<TableRow[]>()

  const {
    data: automat,
    refetch,
    isLoading,
  } = useGetAutomat(id ?? '', dato.fra.toISOString(), dato.til.toISOString())

  const handleSubmitDate = () => {
    refetch()
  }

  const headers = {
    tommedato: { text: 'Tømmedato', sortable: true, initSort: true },
    boksUt: { text: 'Boks ut', sortable: true },
    boksInn: { text: 'Boks inn', sortable: true },
    belopBillett: { text: 'Beløp billett', sortable: true },
    innbetalt: { text: 'Innbetalt', sortable: true },
    avvik: { text: 'Avvik', sortable: true },
    avviksommentar: { text: 'Avvikskommentar', sortable: false },
    billettnummer: { text: 'Billettnummer', sortable: true },
  }

  const footers = {
    tommedato: { text: '' },
    boksUt: { text: '' },
    boksInn: { text: '' },
    belopBillett: { text: '' },
    innbetalt: { text: appendZero(automat?.totalInnbetaling ?? 0) + ' kr' },
    avvik: { text: '' },
    avviksommentar: { text: '' },
    billettnummer: { text: '' },
  }

  const nullstillFilter = async () => {
    setDato({ fra: dato180DagerBak, til: new Date() })
    searchParams.delete('fra')
    searchParams.delete('til')
    setSearchParams(searchParams)
    refetch()
  }

  const handleUpdateFraDato = (fra: Date) => {
    setDato((prev) => ({
      ...prev,
      fra: fra,
    }))
    searchParams.set('fra', fra.toISOString())
    setSearchParams(searchParams)
  }

  const handleUpdateTilDato = (til: Date) => {
    setDato((prev) => ({
      ...prev,
      til: til,
    }))

    searchParams.set('til', til.toISOString())
    setSearchParams(searchParams)
  }

  useEffect(() => {
    setTableRows(
      automat?.tomminger.map((t) => {
        return {
          tableCell: {
            tommedato: { text: t.tommedato ? new Date(t.tommedato) : '' },
            boksUt: { text: t.boksUt },
            boksInn: { text: t.boksInn },
            belopBillett: { text: appendZero(t.belopBillett ?? 0) },
            innbetalt: { text: appendZero(t.belopOpptalt ?? 0) },
            avvik: {
              text: appendZero(t.sumAvvik ?? 0),
              cssClass: avvikssumSkalGenerereError(t.sumAvvik)
                ? 'text-[#f9C66b]'
                : '',
              iconRightOfText: avvikssumSkalGenerereError(t.sumAvvik) ? (
                <StatusIcon status="Avventer" />
              ) : (
                <></>
              ),
            },
            avviksommentar: { text: t.avviksforklaring },
            billettnummer: { text: t.billetnummer },
          } as TableCell,
          data: t,
          htmlElementClickFunction: () => {},
        } as TableRow
      })
    )
  }, [automat])

  const avvikssumSkalGenerereError = (sum: number) => {
    return sum > 50 || sum < -50
  }

  return (
    <>
      <InfoHeader textLarge={`Automat ${automat?.automatnummer}`} />

      <div className="flex space-x-36 pb-10">
        <div>
          <Automatinformasjon
            adresse={automat?.gatenavn + ' ' + automat?.gatenummer}
            type={automat?.automatType ?? ''}
            sistTomt={automat?.sistTomt ? formatDate(automat?.sistTomt) : ''}
            myntnivaa={automat?.myntbeholdning + ',-'}
            arbeidsordreUrl={automat?.arbeidsordreUrl ?? ''}
          />
        </div>

        <div>
          <AutomatHistorikkSok
            fraDato={dato.fra}
            tilDato={dato.til}
            legendText="Søk i historikk"
            updateFraDato={handleUpdateFraDato}
            updateTilDato={handleUpdateTilDato}
            handleSubmitDate={handleSubmitDate}
            nullstill={nullstillFilter}
          />
        </div>
      </div>

      <button
        className="mb-5 pkt-btn"
        onClick={() =>
          download_table_as_csv(
            'automatCSVTable',
            automat?.automatnummer +
              '_' +
              dato.fra.toLocaleDateString('nb') +
              '-' +
              dato.til.toLocaleDateString('nb')
          )
        }
      >
        Last ned
      </button>
      <div className="border">
        <Table
          rows={tableRows ?? []}
          headers={headers}
          footers={footers}
          fixedTable={true}
          isFetchingData={isLoading}
        />
      </div>

      <div className="hidden">
        <AutomatCSVTable automat={automat} />
      </div>
    </>
  )
}
