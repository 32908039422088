import { ReactComponent as SearchIcon } from '../../assets/osgIcons/search-icon.svg'

interface SearchBoxProps {
  onChangeFunction: (action: any) => void
  placeholder?: string
}

export const SearchBox = (props: SearchBoxProps) => {
  const { onChangeFunction, placeholder } = props
  return (
    <div className="pkt-form-group w-80">
      <label htmlFor="sok" className="pkt-form-label">
        Søk etter automat:
      </label>
      <div className="relative">
        <input
          placeholder={placeholder ?? 'Skriv enten automat eller gate'}
          id="sok"
          className="pkt-form-input bg-white py-2 pl-3 pr-10"
          type="search"
          onChange={(e) => onChangeFunction(e.target.value)}
        />
        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          <SearchIcon />
        </span>
      </div>
    </div>
  )
}
