import { ReactNode, useEffect } from 'react'

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

export const ScrollToTop = (props: { children: ReactNode | ReactNode[] }) => {
  useEffect(() => {
    scrollToTop()
  }, [])

  return <>{props.children}</>
}
