import { GetTommerundeDTO } from '../../utils/api/interfaces'
import { formatDate } from '../../utils/dateTimeUtils'
import { PrintablePage } from '../common/printablePage/PrintablePage'
import { ReactComponent as Logo } from '../../assets/osgIcons/oslo-logo.svg'
import { appendZero } from '../../utils/moneyUtils'

interface PrintableTommerundeProps {
  tommerunde: GetTommerundeDTO | undefined
}

export const PrintableTommerunde = (props: PrintableTommerundeProps) => {
  const { tommerunde } = props
  return (
    <PrintablePage>
      <div className="w-full">
        <Logo className="h-logo-large mb-5" />
        <h1 className="osg-sans-bold pb-3">{`Avviksrapport - ${
          tommerunde && formatDate(tommerunde.tommedato)
        }`}</h1>
        <h1 className="pb-4">Bestilt av: {tommerunde?.opprettetAv}</h1>

        <table className="w-full table-auto">
          <thead className="text-center">
            <tr className="h-8 border-b-4 border-gray-500">
              <th>Automat</th>
              <th>Boks ut</th>
              <th>Boks inn</th>
              <th>Beløp billett</th>
              <th>Innbetalt</th>
              <th>Avvik</th>
              <th>Avvikskommentar</th>
            </tr>
          </thead>
          <tbody>
            {tommerunde?.automater.map((a) => (
              <tr
                key={a.automatnummer}
                className="h-8 border-b-4 border-gray-500"
              >
                <td className="border border-x-4 border-gray-500 text-right px-2">
                  {a.automatnummer}
                </td>
                <td className="border border-x-4 border-gray-500 text-right px-2">
                  {a.tommerapport?.boksUt ?? ''}
                </td>
                <td className="border border-x-4 border-gray-500 text-right px-2">
                  {a.tommerapport?.boksInn ?? ''}
                </td>
                <td className="border border-x-4 border-gray-500 text-right px-2">
                  {appendZero(a.tommebillett?.verdi ?? 0)}
                </td>
                <td className="border border-x-4 border-gray-500 text-right px-2">
                  {appendZero(a.opptelling?.opptaltVerdi ?? 0)}
                </td>
                <td className="border border-x-4 border-gray-500 text-right px-2">
                  {a.avvikssum && (a.avvikssum > 50 || a.avvikssum < -50) ? appendZero(a.avvikssum) : "" }
                </td>
                <td className="border border-x-4 border-gray-500 px-2">
                  {a.avviksforklaring?.kommentar}
                </td>
              </tr>
            ))}
            <tr className="h-8 border-b-4 border-gray-500">
              <td className="border border-x-4 border-gray-500 px-2 osg-sans-bold">
                Sum
              </td>
              <td className="border border-x-4 border-gray-500 text-right px-2"></td>
              <td className="border border-x-4 border-gray-500 text-right px-2"></td>
              <td className="border border-x-4 border-gray-500 text-right px-2 osg-sans-bold">
                {appendZero(tommerunde?.automater
                  .flatMap((i) => i.tommebillett?.verdi ?? 0)
                  .reduce((prev, curr) => prev + curr, 0))}
              </td>
              <td className="border border-x-4 border-gray-500 text-right px-2 osg-sans-bold">
              {appendZero(tommerunde?.automater
                  .flatMap((i) => i.opptelling?.opptaltVerdi ?? 0)
                  .reduce((prev, curr) => prev + curr, 0))}
              </td>
              <td className="border border-x-4 border-gray-500 text-right px-2"></td>
              <td className="border border-x-4 border-gray-500 text-right px-2"></td>
            </tr>
          </tbody>
        </table>
        <div className="w-full mt-5">
          Antall automater i liste: {tommerunde?.automater.length}
        </div>
      </div>
    </PrintablePage>
  )
}
