import { ReactComponent as StatusAvventer } from '../../assets/osgIcons/status-avventer.svg'
import { ReactComponent as StatusFullfort } from '../../assets/osgIcons/status-fullfort.svg'
import { ReactComponent as StatusInformation } from '../../assets/osgIcons/status-information.svg'

interface StatusIconProps {
  status: string
}

export const StatusIcon = (props: StatusIconProps) => {
  const { status } = props
  switch (status) {
    case 'Bestilt':
    case 'Penger opptalt':
    case 'Automater tømt':
      return <StatusInformation className="w-5 h-5" />

    case 'Fullført':
      return <StatusFullfort className="w-5 h-5" />

    case 'Avventer':
      return <StatusAvventer className="w-5 h-5" />
  }

  return <></>
}
