import { GetAutomatDTO } from '../../utils/api/interfaces'

interface AutomatCSVTableProps {
  automat: GetAutomatDTO | undefined
}

export const AutomatCSVTable = (props: AutomatCSVTableProps) => {
  const { automat } = props
  return (
    <table id="automatCSVTable">
      <thead>
        <tr>
          <th>Tommedato</th>
          <th>Boks ut</th>
          <th>Boks inn</th>
          <th>Belop billett</th>
          <th>Innbetalt</th>
          <th>Avvik</th>
          <th>Avvikskommentar</th>
          <th>Billettnummer</th>
        </tr>
      </thead>
      <tbody>
        {automat?.tomminger.map((a, index) => (
          <tr key={index}>
            <td>
              {a.tommedato
                ? new Date(a.tommedato).toLocaleDateString('nb')
                : ''}
            </td>
            <td>{a.boksUt ?? ''}</td>
            <td>{a.boksInn ?? ''}</td>
            <td>{a.belopBillett}</td>
            <td>{a.belopOpptalt}</td>
            <td>{a.sumAvvik}</td>
            <td>{a.avviksforklaring ?? ''}</td>
            <td>{a.billetnummer ?? ''}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
