import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetTommerunder } from '../../hooks/useTommerunder'
import { Table, TableCell, TableRow } from '../common/table/Table'
import { InfoHeader } from '../info-header/InfoHeader'
import { StatusIcon } from '../common/StatusIcon'
import { GetTommerundeDTO } from '../../utils/api/interfaces'
import { ReactComponent as ArrowRight } from '../../assets/osgIcons/arrow-right-blue-box.svg'
import { AutomatHistorikkSok } from '../automat/AutomatHistorikkSok'
import { DateQueryFilter } from '../automat/Automat'

export const Tommerunder = () => {
  const [tableRows, setTableRows] = useState<TableRow[]>([])
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const fraDatoQueryParam = new Date(searchParams.get('fra') ?? '')
  const tilDatoQueryParam = new Date(searchParams.get('til') ?? '')

  const dato180DagerBak = (): Date => {
    const date = new Date()
    date.setDate(date.getDate() - 180)
    return date
  }

  const dato1MndFrem = (): Date => {
    const date = new Date()
    date.setMonth(date.getMonth() + 1)
    return date
  }

  const initialValues: DateQueryFilter = {
    fra: isNaN(fraDatoQueryParam.getDate())
      ? dato180DagerBak()
      : fraDatoQueryParam,
    til: isNaN(tilDatoQueryParam.getDate())
      ? dato1MndFrem()
      : tilDatoQueryParam,
  }

  const [dato, setDato] = useState<DateQueryFilter>(initialValues)

  const kolonner = {
    status: { text: 'Status', sortable: true },
    sistTomt: { text: 'Dato for tømming', sortable: true, initSort: true },
    opprettetAv: { text: 'Opprettet av', sortable: false },
    opprettet: { text: 'Opprettet', sortable: true },
  }

  const handleFraDato = (fra: Date) => {
    setDato((prev) => ({
      ...prev,
      fra: fra,
    }))
    searchParams.set('fra', fra.toISOString())
    setSearchParams(searchParams)
  }

  const handleTilDato = (til: Date) => {
    setDato((prev) => ({
      ...prev,
      til: til,
    }))

    searchParams.set('til', til.toISOString())
    setSearchParams(searchParams)
  }

  const {
    data: tommerunder,
    isLoading,
    refetch,
  } = useGetTommerunder(dato.fra.toISOString(), dato.til.toISOString())

  const handleSubmitDate = () => {
    refetch()
  }

  const nullstillFilter = () => {
    setDato({ fra: dato180DagerBak(), til: dato1MndFrem() })
    searchParams.delete('fra')
    searchParams.delete('til')
    setSearchParams(searchParams)
    refetch()
  }

  const routeChange = useCallback(
    (data: GetTommerundeDTO) => {
      let path = `/tommerunde/${data.id}`
      navigate(path)
    },
    [navigate]
  )

  useEffect(() => {
    setTableRows(
      tommerunder?.map((t) => {
        return {
          tableCell: {
            id: { text: t.id },
            status: {
              text: t.status,
              cssClass: 'osg-sans-bold',
              iconLeftOfText: <StatusIcon status={t.status} />,
            },
            sistTomt: { text: new Date(t.tommedato) },
            opprettetAv: { text: t.opprettetAv },
            opprettet: { text: new Date(t.opprettet) },
          } as TableCell,
          data: t,
          htmlElement: <ArrowRight className="cursor-pointer" />,
          htmlElementClickFunction: routeChange,
        } as TableRow
      }) ?? []
    )
  }, [tommerunder, routeChange])

  return (
    <>
      <div className="flex gap-10 items-center">
        <InfoHeader textLarge="Tømmerunder" textSmall="" />
        <AutomatHistorikkSok
          fraDato={dato.fra}
          tilDato={dato.til}
          legendText=""
          updateFraDato={handleFraDato}
          updateTilDato={handleTilDato}
          handleSubmitDate={handleSubmitDate}
          nullstill={nullstillFilter}
        />
      </div>

      <div className="overflow-y-auto max-h-[75vh] border">
        <Table
          rows={tableRows ?? []}
          headers={kolonner}
          isFetchingData={isLoading}
        />
      </div>
    </>
  )
}
