import './App.css'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { PrivateRoutes } from './routes/PrivateRoutes'
import { Layout } from './components/layout/Layout'
import { Automatoversikt } from './components/automatoversikt/Automatoversikt'
import { Tommerunder } from './components/tommerunder/Tommerunder'
import { Automat } from './components/automat/Automat'
import { OpprettTommerunde } from './components/opprett-tommerunde/OpprettTommerunde'
import { Tommerunde } from './components/tommerunde/Tommerunde'
import { AutomatTomminger } from './components/automatTomminger/AutomatTomminger'
import { Forside } from './components/forside/Forside'
import { RedirectRoute } from './routes/RedirectRoute'
import { PublicRoutes } from './routes/PublicRoutes'

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes>
            {/* PRIVATE ROUTES */}
            <Route element={<PrivateRoutes />}>
              <Route path="automatoversikt" element={<Automatoversikt />} />
              <Route path="automat/:id" element={<Automat />} />
              <Route path="tommerunder" element={<Tommerunder />} />
              <Route
                path="opprett-tommerunde"
                element={<OpprettTommerunde />}
              />
              <Route path="tommerunde/:id" element={<Tommerunde />} />
              <Route path="automattomminger" element={<AutomatTomminger />} />
              <Route path="redirect" element={<RedirectRoute />} />
            </Route>

            {/* PUBLIC ROUTES */}
            <Route element={<PublicRoutes />}>
              <Route path="login" element={<Forside />} />
              <Route path="logout" element={<Navigate to="login" />} />
              <Route path="*" element={<Navigate to="login" />} />
            </Route>
          </Routes>
        </Layout>
      </Router>
    </div>
  )
}

export default App
