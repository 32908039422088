import { useEffect, useState } from 'react'
import {
  useGetAutomater,
  useGetHistoriskeAutomater,
} from '../../hooks/useAutomater'
import { Toggle } from '../common/Toggle'
import { InfoHeader } from '../info-header/InfoHeader'
import { Automater } from './Automater'
import { TommerundeSomSkalSendes } from './TommerundeSomSkalSendes'

export interface OpprettTommerundeTableProps {
  rader: OpprettTommerundeRader[] | undefined
  oppdaterListe: any
  isFetchingData: boolean
  showParkeringsautomater: boolean
}

export interface OpprettTommerundeRader {
  automatid: string
  automatnummer: string
  adresse: string
  sistTomt?: Date
  myntnivaa?: number
  finnesITommerunde: boolean
  isParkeringsautomat: boolean
}

export const OpprettTommerunde = () => {
  const { data: automater, isLoading } = useGetAutomater()
  const { data: historiskeAutomater } = useGetHistoriskeAutomater()

  const myntnivaagrense = 4000

  const [automaterSomSendes, setAutomaterSomSendes] = useState<
    OpprettTommerundeRader[]
  >([])

  const [automaterSomIkkeSendes, setAutomaterSomIkkeSendes] = useState<
    OpprettTommerundeRader[]
  >([])

  const [showParkeringsautomater, setShowParkeringsautomater] = useState(true)
  const [toggleAktiv, setToggleAktiv] = useState(true)

  const oppdaterAutomaterSomSendes = (r: OpprettTommerundeRader) => {
    let temp = automaterSomSendes.filter((a) => a !== r)
    setAutomaterSomSendes(temp)
    setAutomaterSomIkkeSendes([...automaterSomIkkeSendes, r])
  }

  const oppdaterAutomaterSomIkkeSendes = (r: OpprettTommerundeRader) => {
    let temp = automaterSomIkkeSendes.filter((a) => a !== r)
    setAutomaterSomIkkeSendes(temp)
    setAutomaterSomSendes([...automaterSomSendes, r])
  }

  useEffect(() => {
    let currAutomater = toggleAktiv ? automater : historiskeAutomater

    let initAutomaterSomSkalSendes = currAutomater
      ?.sort((a, b) => b.myntbeholdning - a.myntbeholdning)
      .filter(
        (a) => a.myntbeholdning > myntnivaagrense && !a.finnesIAktivTommerunde
      )
      .slice(0, 25)

    let initAutomaterSomIkkeSendes = currAutomater?.filter(
      (a) => !initAutomaterSomSkalSendes?.includes(a)
    )

    setAutomaterSomSendes(
      initAutomaterSomSkalSendes?.map((a) => {
        return {
          automatid: a.automatId,
          automatnummer: a.automatnummer,
          adresse: a.gatenavn,
          sistTomt: a.sistTomt,
          myntnivaa: a.myntbeholdning,
          finnesITommerunde: a.finnesIAktivTommerunde,
          isParkeringsautomat: a.isParkeringsautomat,
        } as OpprettTommerundeRader
      }) ?? []
    )

    setAutomaterSomIkkeSendes(
      initAutomaterSomIkkeSendes?.map((a) => {
        return {
          automatid: a.automatId,
          automatnummer: a.automatnummer,
          adresse: a.gatenavn,
          sistTomt: a.sistTomt,
          myntnivaa: a.myntbeholdning,
          finnesITommerunde: a.finnesIAktivTommerunde,
          isParkeringsautomat: a.isParkeringsautomat,
        } as OpprettTommerundeRader
      }) ?? []
    )
  }, [isLoading, toggleAktiv, automater, historiskeAutomater])

  useEffect(() => {}, [showParkeringsautomater])

  return (
    <>
      <div className="grid grid-cols-2">
        <InfoHeader
          textLarge="Tømmerunde"
          textSmall="Juster foreslått utvalg og opprett liste til tømming"
        />
        <div className='flex justify-end'>
          <div className="pb-5 pt-14">
            <Toggle
              leftText="P-automater"
              rightText="Piggdekkautomater"
              toggleFunction={() =>
                setShowParkeringsautomater(!showParkeringsautomater)
              }
            />
            <div className="mt-5 ml-11">
              <Toggle
                leftText="Aktive"
                rightText="Inaktive"
                toggleFunction={(val) => setToggleAktiv(val)}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <TommerundeSomSkalSendes
          rader={automaterSomSendes}
          oppdaterListe={oppdaterAutomaterSomSendes}
          isFetchingData={isLoading}
          showParkeringsautomater={showParkeringsautomater}
        />
      </div>

      <div>
        <Automater
          rader={automaterSomIkkeSendes}
          oppdaterListe={oppdaterAutomaterSomIkkeSendes}
          isFetchingData={isLoading}
          showParkeringsautomater={showParkeringsautomater}
        />
      </div>
    </>
  )
}
