import { ReactComponent as Lenke } from '../../assets/icons/lenke.svg'

interface AutomatinformasjonProps {
  adresse: string
  type: string
  sistTomt: string
  myntnivaa: string
  arbeidsordreUrl: string
}

export const Automatinformasjon = (props: AutomatinformasjonProps) => {
  const { adresse, type, sistTomt, myntnivaa, arbeidsordreUrl } = props
  return (
    <>
      <dl className="pkt-dl m-0">
        <dt className="osg-sans-bold mb-1">Adresse: </dt>
        <dd>{adresse}</dd>

        <dt className="osg-sans-bold mb-1">Type: </dt>
        <dd>{type}</dd>

        <dt className="osg-sans-bold mb-1">Sist tømt: </dt>
        <dd>{sistTomt}</dd>

        <dt className="osg-sans-bold">Myntnivå (p.t): </dt>
        <dd>{myntnivaa}</dd>

        <dt className="osg-sans-bold">Arbeidsordre: </dt>
        <dd className="underline flex gap-2">
          <a target="_blank" rel="noopener noreferrer" href={arbeidsordreUrl}>
            {' '}
            Åpne i ny fane
          </a>
          <Lenke className="w-7 h-7 fill-current" />
        </dd>
      </dl>
    </>
  )
}
