import { ReactComponent as ArrowThinDown } from '../../assets/osgIcons/arrow-down-thin.svg'
import { ReactComponent as MinusCircle } from '../../assets/osgIcons/minus-circle.svg'
import { Table, TableCell, TableRow } from '../common/table/Table'
import { OpprettTommerundeTableProps } from './OpprettTommerunde'
import { useEffect, useState } from 'react'
import { OpprettTommerundeModal } from './OpprettTommerundeModal'

export const TommerundeSomSkalSendes = (props: OpprettTommerundeTableProps) => {
  const { rader, oppdaterListe, isFetchingData, showParkeringsautomater } = props
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [tableRows, setTableRows] = useState<TableRow[]>()

  const headers = {
    automatnummer: { text: 'Automatnummer', sortable: true },
    adresse: { text: 'Adresse', sortable: true },
    sistTomt: { text: 'Sist tømt', sortable: true },
    myntnivaa: { text: 'Myntnivå d.d', sortable: true, initSort: true },
  }

  useEffect(() => {
    setTableRows(
      rader
        ?.filter((a) =>
          showParkeringsautomater
            ? a.isParkeringsautomat
            : !a.isParkeringsautomat
        )
        .map((a) => {
          return {
            tableCell: {
              automatnummer: { text: a.automatnummer },
              adresse: { text: a.adresse },
              sistTomt: { text: a.sistTomt ? new Date(a.sistTomt) : '' },
              myntnivaa: { text: a.myntnivaa },
            } as TableCell,
            data: a,
            htmlElement: <MinusCircle />,
            htmlElementClickFunction: oppdaterListe,
          } as TableRow
        })
    )
  }, [rader, showParkeringsautomater, oppdaterListe])

  return (
    <>
      <div className="overflow-auto max-h-[30vh] border">
        <Table
          rows={tableRows ?? []}
          headers={headers}
          isFetchingData={isFetchingData}
        />
      </div>

      <div className="flex space-x-8 justify-end items-center text-end py-6 ">
        <p className="osg-sans-bold" data-testid="antall-automater">
          Antall automater i lista: {tableRows?.length}
        </p>
        <button
          onClick={() => setModalIsOpen(!modalIsOpen)}
          disabled={tableRows?.length === 0}
          className="pkt-btn pkt-btn--medium pkt-btn--icon-right justify-between"
        >
          <ArrowThinDown className="pkt-btn__icon fill-current -rotate-90 " />
          <span className="pkt-btn__text">Opprett tømmerunde</span>
        </button>
      </div>

      {modalIsOpen ? (
        <OpprettTommerundeModal
          automater={
            rader?.filter((a) =>
              showParkeringsautomater
                ? a.isParkeringsautomat
                : !a.isParkeringsautomat
            ) ?? []
          }
          setModalIsOpen={setModalIsOpen}
        />
      ) : (
        <></>
      )}
    </>
  )
}
