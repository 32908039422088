import { useState } from 'react'

interface ToggleProps {
  leftText: string
  rightText: string
  toggleFunction: (val: boolean) => void
}

export const Toggle = (props: ToggleProps) => {
  const { leftText, rightText, toggleFunction } = props
  const [enabled, setEnabled] = useState(false)

  return (
    <div className="flex">
      <label className="inline-flex relative items-center mr-5 cursor-pointer">
        <span
          className={`mr-2 text-sm ${
            !enabled ? 'osg-sans-bold' : 'osg-sans-regular'
          } text-gray-900 text-center`}
        >
          {leftText}
        </span>
        <input
          type="checkbox"
          className="sr-only peer"
          checked={enabled}
          readOnly
        />

        <div
          onClick={() => {
            toggleFunction(enabled)
            setEnabled(!enabled)
          }}
          className="pkt-form-input w-10 h-6 bg-white border-[#2a2859] rounded-full peer-checked:after:translate-x-full after:absolute after:bottom-[0.1rem] after:left-[0.155rem] after:bg-[#2a2859] after:rounded-full after:h-4 after:w-4 after:transition-all"
        ></div>

        <span
          className={`ml-2 text-sm ${
            enabled ? 'osg-sans-bold' : 'osg-sans-regular'
          } text-gray-900 text-center`}
        >
          {rightText}
        </span>
      </label>
    </div>
  )
}
