import { useRef } from 'react'
import { GetTommerundeDTO } from '../../utils/api/interfaces'

interface UploadFileProps {
  tommerunde?: GetTommerundeDTO
  fil: File | null
  setFil: (action: any) => void
}

export const UploadFile = (props: UploadFileProps) => {
  const { tommerunde, fil, setFil } = props
  const fileInput = useRef<HTMLInputElement | null>(null)
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const stopReload = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleRemoveFile = () => {
    setFil(null)
  }

  const selectFile = () => {
    fileInput.current?.click()
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFil(e.target.files?.item(0) ?? null)
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    stopReload(e)
    if (e.dataTransfer.files?.item(0)) {
      setFil(e.dataTransfer.files?.item(0))
    } else {
      alert('Kunne ikke laste opp dokument ved drag-n-drop')
    }
  }

  const handleEnter = () => {
    let curr = buttonRef.current
    curr?.classList.add('bg-gray-200')
  }
  const handleExit = () => {
    let curr = buttonRef.current
    curr?.classList.remove('bg-gray-200')
  }
  return (
    <div className={`${tommerunde?.status === 'Fullført' && 'hidden'}`}>
      {fil != null ? (
        <div className="flex mb-3">
          <input
            hidden
            id={tommerunde?.id}
            ref={fileInput}
            type="file"
            onChange={(e) => handleFileChange(e)}
          />
          <div
            className="underline text-hoverblue cursor-pointer col-span-2"
            onClick={() => selectFile()}
          >
            {fil.name}
          </div>
          <div
            className="cursor-pointer underline ml-4 "
            onClick={() => handleRemoveFile()}
          >
            Nullstill
          </div>
        </div>
      ) : (
        <div
          onDragEnter={() => handleEnter()}
          onDragExit={() => handleExit()}
          onDragOver={(e) => stopReload(e)}
          onDrop={(e: React.DragEvent<HTMLDivElement>) => handleDrop(e)}
        >
          <input
            hidden
            id={tommerunde?.id}
            ref={fileInput}
            type="file"
            onChange={(e) => handleFileChange(e)}
          />
          <button
            id={tommerunde?.id}
            ref={buttonRef}
            className="pkt-btn pkt-btn--tertiary btn--icon-left border-dotted border-4"
            onClick={() => selectFile()}
          >
            {/* <File className="fill-current" /> */}
            <span>Velg fil eller dra fil</span>
          </button>
        </div>
      )}
    </div>
  )
}
