import { AxiosRequestConfig } from 'axios'
import { apiClient, getUser } from './client'
import {
  AutomatTommerapportDTO,
  GetAutomatDTO,
  GetAutomaterDTO,
  GetAutomatTommingerDTO,
  GetTommerundeDTO,
  ImportResultatDTO,
  PutTommerundeManuellOpptellingDTO,
  PutTommerundeManuellTommingDTO,
} from './interfaces'

const client = apiClient()

const config = (): AxiosRequestConfig<any> | undefined => {
  const user = getUser()
  const accessToken = user?.access_token

  if (!accessToken) return undefined

  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }
}

export const getTommerunder = (start: string, slutt: string) =>
  client.get<GetTommerundeDTO[]>(
    `tommerunde?start=${start}&slutt=${slutt}`,
    config()
  )
export const getTommerunde = (id: string) =>
  client.get<GetTommerundeDTO>(`tommerunde/${id}`, config())
export const putTommerapport = (
  tommerundeId: string,
  automatId: string,
  data: AutomatTommerapportDTO
) =>
  client.put(
    `tommerunde/${tommerundeId}/${automatId}/tommerapport`,
    data,
    config()
  )

export const putTommerundeManuellOpptelling = (
  tommerundeId: string,
  automatId: string,
  data: PutTommerundeManuellOpptellingDTO
) =>
  client.put<PutTommerundeManuellOpptellingDTO>(
    `tommerunde/${tommerundeId}/${automatId}/manuellOpptelling`,
    data,
    config()
  )
export const putTommerundeManuellTomming = (
  tommerundeId: string,
  automatId: string,
  data: PutTommerundeManuellTommingDTO
) =>
  client.put<PutTommerundeManuellTommingDTO>(
    `tommerunde/${tommerundeId}/${automatId}/manuellTomming`,
    data,
    config()
  )
export const putTommerundeTaAutomatUt = (
  tommerundeId: string,
  automatId: string
) =>
  client.put(
    `tommerunde/${tommerundeId}/${automatId}/taAutomatUt`,
    {},
    config()
  )
export const putTommerundeLeggAutomatTilbake = (
  tommerundeId: string,
  automatId: string
) =>
  client.put(
    `tommerunde/${tommerundeId}/${automatId}/leggAutomatTilbake`,
    {},
    config()
  )
export const putTommerundeAutomatAvviksforklaring = (
  tommerundeId: string,
  automatId: string,
  forklaring: string
) =>
  client.put(
    `tommerunde/${tommerundeId}/${automatId}/avviksforklaring`,
    { kommentar: forklaring },
    config()
  )

export const postTommerunde = (
  automatIderTilTomming: string[],
  tommedato: Date,
  ikkeSendBestilling: boolean
) => client.post('tommerunde', { automatIderTilTomming, tommedato, ikkeSendBestilling }, config())

export const postTommerundeFullfor = (tommerundeId: string) =>
  client.post(`tommerunde/${tommerundeId}/fullfor`, {}, config())

export const putCerFilTommerunde = (tommerundeId: string, fil: FormData) =>
  client.put<ImportResultatDTO>(`tommerunde/${tommerundeId}/lastoppCerFil`, fil, config())

export const putHTFilTommerunde = (tommerundeId: string, fil: FormData) =>
  client.put<ImportResultatDTO>(`tommerunde/${tommerundeId}/lastoppHtFil`, fil, config())

export const getAutomater = () =>
  client.get<GetAutomaterDTO[]>('automat', config())

export const getAutomatTomminger = (
  start: Date,
  slutt: Date,
  automatnummer?: string,
  automatType?: string,
  boksInn?: number,
  boksUt?: number,
) =>
  client.get<GetAutomatTommingerDTO>(
    `tommerunde/automattomminger?start=${start.toUTCString()}&slutt=${slutt.toUTCString()}&automatnummerquery=${automatnummer ?? ""}${automatType ? `&automatType=${automatType}`  : ""}&boksInn=${boksInn ?? ''}&boksUt=${boksUt ?? ''}`,
    config()
  )
export const getHistoriskeAutomater = () =>
  client.get<GetAutomaterDTO[]>('automat/historiske', config())

export const getAutomat = (id: string, start: string, slutt: string) =>
  client.get<GetAutomatDTO>(
    `automat/${id}?start=${start}&slutt=${slutt}`,
    config()
  )
