import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useGetAutomater,
  useGetHistoriskeAutomater,
} from '../../hooks/useAutomater'
import { GetAutomaterDTO } from '../../utils/api/interfaces'
import { SearchBox } from '../common/SearchBox'
import { Table, TableCell, TableRow } from '../common/table/Table'
import { InfoHeader } from '../info-header/InfoHeader'
import { ReactComponent as ArrowRight } from '../../assets/osgIcons/arrow-right-blue-box.svg'
import { Toggle } from '../common/Toggle'

export const Automatoversikt = () => {
  const { data: automater, isLoading } = useGetAutomater()
  const { data: historiskeAutomater } = useGetHistoriskeAutomater()
  const [tableRows, setTableRows] = useState<TableRow[]>()
  const [searchVal, setSearchVal] = useState('')
  const [toggleAktiv, setToggleAktiv] = useState(true)

  const navigate = useNavigate()

  const headers = {
    automatnummer: { text: 'Automatnummer', sortable: true, initSort: true },
    adresse: { text: 'Adresse', sortable: true },
    status: { text: 'Status', sortable: false },
    sistTomt: { text: 'Sist tømt', sortable: true },
    boksInn: { text: 'Boks inn', sortable: true },
    myntnivaa: { text: 'Myntnivå d.d', sortable: true },
  }

  const routeChange = useCallback(
    (data: GetAutomaterDTO) => {
      let path = `/automat/${data.automatId}`
      navigate(path)
    },
    [navigate]
  )

  useEffect(() => {
    let currAutomater = toggleAktiv ? automater : historiskeAutomater

    let temp = currAutomater?.filter(
      (a) =>
        a.gatenavn.toString().toLowerCase().includes(searchVal.toLowerCase()) ||
        a.automatnummer
          .toString()
          .toLowerCase()
          .includes(searchVal.toLowerCase())
    )

    setTableRows(
      temp?.map((a) => {
        return {
          tableCell: {
            automatnummer: { text: a.automatnummer },
            adresse: { text: a.gatenavn + ' ' + a.gatenummer },
            status: { text: toggleAktiv ? 'Aktiv' : 'Inaktiv' },
            sistTomt: { text: a.sistTomt ? new Date(a.sistTomt) : '' },
            boksInn: { text: a.boksInn }, 
            myntnivaa: { text: a.myntbeholdning },
          } as TableCell,
          data: a,
          htmlElementClickFunction: routeChange,
          htmlElement: <ArrowRight className="cursor-pointer" />,
        } as TableRow
      })
    )
  }, [toggleAktiv, historiskeAutomater, routeChange, searchVal, automater])

  return (
    <>
      <InfoHeader textLarge="Automatoversikt" />

      <div className="flex gap-5">
        <SearchBox onChangeFunction={setSearchVal} />
        <div className="text-center">
          <p className="pr-7 pt-3 osg-sans-bold">Status</p>
          <Toggle
            leftText="Aktiv"
            rightText="Inaktiv"
            toggleFunction={(val) => setToggleAktiv(val)}
          />
        </div>
      </div>

      <div className="overflow-y-auto max-h-[60vh] border">
        <Table
          rows={tableRows ?? []}
          headers={headers}
          isFetchingData={isLoading}
        />
      </div>
    </>
  )
}
