import { AntallKronerInput } from './ManuellRegistreringModal'

interface ManuellRegistreringAntallKronerProps {
  setAntallKroner: (val: any) => void
  antallKroner: AntallKronerInput
}

export const ManuellRegistreringAntallKroner = (
  props: ManuellRegistreringAntallKronerProps
) => {
  const { setAntallKroner, antallKroner } = props

  return (
    <div>
      <div className="grid grid-cols-9 gap-10">
        <div className="flex items-center mt-5">Antall</div>

        <div className='col-span-2'>
          <label htmlFor="1krInput" className="block pkt-form-label text-center">
            1 kr
          </label>
          <input
            type="text"
            className="pkt-form-input"
            id="1krInput"
            value={antallKroner.enkroner}
            onChange={(e) =>
              setAntallKroner((prev: AntallKronerInput) => ({
                ...prev,
                enkroner: parseInt(e.target.value) || 0,
              }))
            }
          />
        </div>

        <div className='col-span-2'>
          <label htmlFor="5krInput" className="block pkt-form-label text-center">
            5 kr
          </label>
          <input
            type="text"
            className="pkt-form-input"
            id="5krInput"
            value={antallKroner.femkroner}
            onChange={(e) =>
              setAntallKroner((prev: AntallKronerInput) => ({
                ...prev,
                femkroner: parseInt(e.target.value) || 0,
              }))
            }
          />
        </div>
        <div className='col-span-2'>
          <label htmlFor="10krInput" className="block pkt-form-label text-center">
            10 kr
          </label>
          <input
            type="text"
            className="pkt-form-input"
            id="10krInput"
            value={antallKroner.tikroner}
            onChange={(e) =>
              setAntallKroner((prev: AntallKronerInput) => ({
                ...prev,
                tikroner: parseInt(e.target.value) || 0,
              }))
            }
          />
        </div>
        <div className='col-span-2'>
          <label htmlFor="20krInput" className="block pkt-form-label text-center">
            20 kr
          </label>
          <input
            type="text"
            className="pkt-form-input"
            id="20krInput"
            value={antallKroner.tjuekroner}
            onChange={(e) =>
              setAntallKroner((prev: AntallKronerInput) => ({
                ...prev,
                tjuekroner: parseInt(e.target.value) || 0,
              }))
            }
          />
        </div>
      </div>

      <div className="grid grid-cols-9 gap-10 mt-8 pt-8">
        <div className="flex items-center">Beløp</div>

        <div className="border-solid border-b-[3px] border-[#2a2859] text-center col-span-2">
          <p>{antallKroner.enkroner * 1} kr</p>
        </div>

        <div className="border-solid border-b-[3px] border-[#2a2859] text-center col-span-2">
          <p>{antallKroner.femkroner * 5} kr</p>
        </div>
        <div className="border-solid border-b-[3px] border-[#2a2859] text-center col-span-2">
          <p>{antallKroner.tikroner * 10} kr</p>
        </div>
        <div className="border-solid border-b-[3px] border-[#2a2859] text-center col-span-2">
          <p>{antallKroner.tjuekroner * 20} kr</p>
        </div>
      </div>
    </div>
  )
}
