import { useEffect, useState } from 'react'
import { Table, TableCell, TableHeader, TableRow } from '../common/table/Table'
import { ReactComponent as PlusCircle } from '../../assets/osgIcons/plus-circle.svg'
import { AutomatTattUtAvRundeDTO } from '../../utils/api/interfaces'

interface AutomaterSomIkkeSkalArkiveresProps {
  rader: AutomatTattUtAvRundeDTO[]
  oppdaterListe: (action: any) => void
  isFetchingData: boolean
  kolonner: TableHeader
  erFullfort: boolean
}

export const AutomaterSomIkkeSkalArkiveres = (props: AutomaterSomIkkeSkalArkiveresProps) => {
  const { rader, oppdaterListe, isFetchingData, kolonner, erFullfort } = props

  const [tableRows, setTableRows] = useState<TableRow[]>()

  useEffect(() => {
    setTableRows(
      rader?.map((a) => {
        return {
          tableCell: {
            automat: { text: a.automatnummer },
            boksUt: { text: '' },
            boksInn: { text: '' },
            belopBillett: { text: '' },
            tellesum: { text: '' },
            avviksum: { text: '' },
            avvikskommentar: { text: '' },
          } as TableCell,
          data: a,
          htmlElement: <PlusCircle className={`${erFullfort && 'hidden'}`} />,
          htmlElementClickFunction: oppdaterListe,
        } as TableRow
      })
    )
  }, [rader, erFullfort, oppdaterListe])

  return (
    <div className="border" id="automaterSomIkkeSkalArkiveres">
      <Table
        rows={tableRows ?? []}
        headers={kolonner}
        isFetchingData={isFetchingData}
      />
    </div>
  )
}
