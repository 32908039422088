import { useAuth } from 'react-oidc-context'
import { useLocation } from 'react-router'
import { Navigate, Outlet } from 'react-router-dom'

export const PrivateRoutes = () => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.isLoading) {
    return <h1 style={{ textAlign: 'center' }}>Laster...</h1>
  }

  if (auth.error) {
    auth.signoutRedirect();
  }
  
  return auth.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  )
}
