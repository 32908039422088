export const download_table_as_csv = (table_id, filnavn) => {
    let data = "sep=,\n";
    const tableData = [];
    const rows = document.querySelectorAll('table#' + table_id + ' tr');
    for (const row of rows) {
      const rowData = [];
      for (const [index, column] of row.querySelectorAll("th, td").entries()) {
        // To retain the commas in the "Description" column, we can enclose those fields in quotation marks.
        if ((index + 1) % 3 === 0) {
          rowData.push('"' + column.innerText + '"');
        } else {
          rowData.push(column.innerText);
        }
      }
      tableData.push(rowData.join(","));
    }
    data += tableData.join("\n");
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
    a.setAttribute("download", filnavn + ".csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
